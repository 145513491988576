<template>
  <div id="aggregatedSkillDashborad" class="scrollBar scrollableDiv">
    <table border="1" id="dashboardTable" class="table-hover dashboardTable" style="height: auto;">
        <colgroup id="dashboardColGroupTbl">
            <col style="width: 140px;">
            <col style="width: 140px;">
            <col style="width: 140px;">
            <col style="width: 100px;">
            <col style="width: 100px;">
            <col style="width: 60px;">
            <col style="width: 60px;">
            <col style="width: 60px;">
            <col style="width: 60px;">
            <col style="width: 60px;">
            <col style="width: 60px;">
            <col style="width: 60px;">
            <col style="width: 60px;">
            <col style="width: 60px;">
            <col style="width: 60px;">
            <col style="width: 60px;">
            <col style="width: 60px;">
            <col style="width: 60px;">
            <col style="width: 60px;">
            <col style="width: 60px;">
            <col style="width: 60px;">
            <col style="width: 60px;">
            <col style="width: 70px;">
            <col style="width: 60px;">
        </colgroup>
        <thead id="dashboardHeaderTbl">
            <tr>
                <th colspan="5" class="dasboardTitle facilityCol" style="padding: 3px;">Aggregated Skills Dashboard *NEW*</th>
                <th colspan="5" class="detailsHeader" style="padding: 3px;">Patient Census</th>
                <th colspan="2" class="detailsHeader" style="padding: 3px;">Premium Labor</th>
                <th colspan="6" class="detailsHeader" style="padding: 3px;">Nursing</th>
                <th colspan="6" class="detailsHeader" style="padding: 3px;">Support</th>
            </tr>
            <tr>
                <th colspan="1" class="drivingHeader facilityCol" style="padding: 3px;">Facility</th>
                <th colspan="1" class="drivingHeader clusterCol" style="padding: 3px;">Cluster</th>
                <th colspan="1" class="drivingHeader deptNameCol" style="padding: 3px;">Department</th>
                <th colspan="1" class="drivingHeader deptCodeCol" style="padding: 3px;">Code</th>
                <th colspan="1" class="drivingHeader shiftCol" style="padding: 3px;">Shift Summary</th>
                <th colspan="1" class="detailsHeader" style="padding: 3px;">Fore</th>
                <th colspan="1" class="detailsHeader" style="padding: 3px;">Actual</th>
                <th colspan="1" class="detailsHeader" style="padding: 3px;">StaffTo</th>
                <th colspan="1" class="detailsHeader" style="padding: 3px;">1:1 Stable</th>
                <th colspan="1" class="detailsHeader" style="padding: 3px;">1:1 Unstable</th>
                <th colspan="1" class="detailsHeader" style="padding: 3px;">OT</th>
                <th colspan="1" class="detailsHeader" style="padding: 3px;">C/L</th>
                <th colspan="1" class="detailsHeader" style="padding: 3px;">RN Min-Max</th>
                <th colspan="1" class="detailsHeader" style="padding: 3px;">RN EXP &lt; 1yr</th>
                <th colspan="1" class="detailsHeader" style="padding: 3px;">Chg RN</th>
                <th colspan="1" class="detailsHeader" style="padding: 3px;">RN</th>
                <th class="detailsHeader" style="padding: 3px;">LPN</th>
                <th colspan="1" class="detailsHeader" style="padding: 3px;">Resource RN</th>
                <th class="detailsHeader" style="padding: 3px;">S Min-Max</th>
                <th class="detailsHeader" style="padding: 3px;">PCT</th>
                <th class="detailsHeader" style="padding: 3px;">Clerical</th>
                <th class="detailsHeader" style="padding: 3px;">Clinical Tech</th>
                <th class="detailsHeader" style="padding: 3px; width: 70px;">Specialists</th>
                <th class="detailsHeader" style="padding: 3px;">FAN/EVS</th>
            </tr>
        </thead>
        <tbody id="dashboardBodyTbl">
            <template v-for="facility in dashboardData" :key="facility">
                <template v-for="(cluster, cindex) in facility.Clusters" :key="cindex">
                    <template v-for="(department, dindex) in cluster.Departments" :key="dindex">
                        <template v-for="(summary, sindex) in department.Summaries" :key="sindex">
                            <tr>
                                <template v-if="cindex == 0 && dindex == 0 && sindex == 0">
                                    <td :facilityid="facility.FacilityId" class="drivingHeader facilityCol" :rowspan="facility.TotalRows">{{facility.FacilityName}}</td>
                                </template>
                                <template v-if="dindex == 0 && sindex == 0">
                                    <td :clusterid="cluster.ClusterId" class="drivingHeader clusterCol" :rowspan="cluster.TotalRows">{{cluster.ClusterName}}</td>
                                </template>
                                <template v-if="sindex == 0">
                                    <td :departmentid="department.DepartmentId" class="drivingHeader deptNameCol" :rowspan="department.TotalRows">{{department.DepartmentName}}</td>
                                    <td :departmentid="department.DepartmentId" class="drivingHeader deptCodeCol" :rowspan="department.TotalRows">{{department.DepartmentCode}}</td>
                                </template>
                                <td class="drivingHeader shiftCell shiftCol" @click="showActionable(summary.DepartmentSummaryId, summary.StartDateTime, 1)">
                                    {{getSummaryTime(summary.StartDateTime)}}
                                </td>
                                <td class="gridStandard forecastcensus" style="mso-number-format:'@'" 
                                    @click="showEditStaffToForecastCensus(summary.DepartmentSummaryId, summary.StartDateTime)"
                                    columnname="Fore" :departmentname="department.DepartmentName" :departmentid="department.DepartmentId">
                                    {{summary.Metrics[0].ForecastCensus}}
                                </td>
                                <td class="gridStandard actual-census" style="mso-number-format:'@'"
                                columnname="Actual" :departmentname="department.DepartmentName" :departmentid="department.DepartmentId">
                                    {{summary.Metrics[0].ActualCensus != null ? summary.Metrics[0].ActualCensus : '-'}}
                                </td>
                                <td class="gridStandard staffto" style="mso-number-format:'@'" 
                                    @click="showEditStaffToForecastCensus(summary.DepartmentSummaryId, summary.StartDateTime)"
                                    columnname="StaffTo" :departmentname="department.DepartmentName" :departmentid="department.DepartmentId">
                                    {{summary.Metrics[0].StaffTo!= null ? summary.Metrics[0].StaffTo : '-'}}
                                </td>
                                <td class="gridStandard" style="mso-number-format:'@'" 
                                    columnname="1:1 Stable" :departmentname="department.DepartmentName" :departmentid="department.DepartmentId">
                                    {{getStableCount(summary.Metrics)}}
                                </td>
                                <td class="gridStandard " style="mso-number-format:'@'" 
                                    columnname="1:1 Unstable" :departmentname="department.DepartmentName" :departmentid="department.DepartmentId">
                                    {{getUnstableCount(summary.Metrics)}}
                                </td>  
                                <td :class="['gridStandard ', getOverTimeCalculationCSS(summary.Metrics)]" 
                                    style="mso-number-format:'@'" :title="getOverTimeCalculationTitle(summary.Metrics)"
                                    @click="showActionable(summary.DepartmentSummaryId, summary.StartDateTime, 1)"
                                    columnname="OT" :departmentname="department.DepartmentName" :departmentid="department.DepartmentId">
                                        <span>{{getOverTimeCalculation(summary.Metrics)}}</span>
                                </td>
                                <td :class="['gridStandard ', getCLOverTimeCalculationCSS(summary.Metrics)]"   style="mso-number-format:'@'" 
                                    @click="loadPremiumLaborBreakdownPopupOnCLClick(summary.DepartmentSummaryId, summary.StartDateTime)"
                                    columnname="C/L" :departmentname="department.DepartmentName" :departmentid="department.DepartmentId">
                                    <span>{{summary.Metrics[0].StaffCountsByPL}}</span>
                                </td>
                                <td class="gridStandard w60" style="mso-number-format:'@'" 
                                    columnname="RN Min-Max" :departmentname="department.DepartmentName" :departmentid="department.DepartmentId">
                                    <span>{{getRNMinMax(summary.Metrics)}}</span>
                                </td>
                                <td :class="['gridStandard ', getRNExpLess1YrCss(summary.Metrics[0])]" 
                                    style="mso-number-format:'@'" :title="getRNExpLess1YrTitle(summary.Metrics[0])" 
                                    @click="showActionable(summary.DepartmentSummaryId, summary.StartDateTime, 1)"
                                    columnname="RN EXP < 1yr" :departmentname="department.DepartmentName" :departmentid="department.DepartmentId">
                                    <span>{{summary.Metrics[0].RNExperienceLt1}}</span>
                                </td>
                                <template v-for="nsSkill in nursingSkills" :key="nsSkill">
                                    <td :class="[getValueBySkillGroupCss(nsSkill, summary.Metrics)]" 
                                        style="mso-number-format:'@'" :title="getValueBySkillGroupTitle(nsSkill, summary.Metrics)"
                                        @click="showActionable(summary.DepartmentSummaryId, summary.StartDateTime, 2)"
                                        :columnname="nsSkill" :departmentname="department.DepartmentName" :departmentid="department.DepartmentId">
                                        <span>{{getValueBySkillGroup(nsSkill, summary.Metrics)}}</span>
                                    </td>
                                </template>
                                <td class="gridStandard " style="mso-number-format:'@'"
                                    columnname="S Min-Max" :departmentname="department.DepartmentName" :departmentid="department.DepartmentId">
                                    {{summary.Metrics[0].SMin + " - " + (summary.Metrics[0].SMax === maxCensusEntry ? maxCensusValue : summary.Metrics[0].SMax )}}
                                </td>
                                <template v-for="spSkill in supportSkills" :key="spSkill">
                                    <td :class="[getValueBySkillGroupCss(spSkill, summary.Metrics)]" 
                                        style="mso-number-format:'@'" :title="getValueBySkillGroupTitle(spSkill, summary.Metrics)"
                                        @click="showActionable(summary.DepartmentSummaryId, summary.StartDateTime, 2)"
                                        :columnname="spSkill" :departmentname="department.DepartmentName" :departmentid="department.DepartmentId">
                                        <span>{{getValueBySkillGroup(spSkill, summary.Metrics)}}</span>
                                    </td>
                                </template>
                            </tr>
                        </template>
                    </template>
                </template> 
            </template> 
        </tbody>
    </table>
    <!-- Toaster -->
    <div v-if="isToasterVisible"> 
      <ToasterPopup @closeModal="closeToasterPopup"
                    :msgValue="msgValue"
                    :classFlag="classFlag"></ToasterPopup>
    </div>
    <div v-if="isContractLaborCalloutVisible">
        <ContractLaborCalloutPopup :contractLaborCalloutDetails="contractLaborCalloutDetails"
                                   :contractLaborCalloutFlags="contractLaborCalloutFlags"
                                   @close-modal="closeCrtLbrCalloutPopup"
                                   ></ContractLaborCalloutPopup>
    </div>
    <div v-if="isActionableModelVisible"> 
      <Actionable :actionableConfig="actionableData"
                  @close-modal="closeActionablePopup"
                    ></Actionable>
    </div>
    <div v-if="isStafToForecastModelVisible"> 
      <StaffToForecastCensus :staffToForecastInput="staffToForecastData"
                  @close-modal="closeStaffToForecastPopup"
                    ></StaffToForecastCensus>
    </div>
    <div v-if="isPremiumLaborBreakdownVisible">
      <PremiumLaborBreakdown @closeModal="closePremiumLaborBreakdownPopup"
                    :premLaborBreakdownInput="premLaborBreakdownInput"
                    :isBreakdownfrom7Day="false"></PremiumLaborBreakdown>
    </div> 
  </div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import moment from "moment";
import jslinq from "jslinq";
import { mapState } from "vuex";

//import models and enum
import { DashboardRelations, AppMode} from "@/enums/enum";
import {TransactionInfo, MetricsFilter, Filter, StartEndDateTimeModel } from "@/models/dashboardmain/index";
import {KeyPairModel} from '@/models/common/index';
import { Profile } from '@/models/profile/index';
import { PremiumLaborKeyValuePair } from "@/models/dashboardSevenDay/index";

//import components
import ToasterPopup from "../shared/ToasterPopup.vue";
import ContractLaborCalloutPopup from './ContractLaborCalloutPopup.vue';
//@ts-ignore
import Actionable from "../actionable/Actionable.vue";
import StaffToForecastCensus from "./StaffToForecastCensus.vue";
import PremiumLaborBreakdown from '../dashboardSevenDay/PremiumLaborBreakdown.vue';
import { useAppInsights } from '../../store/modules/AppInsights';


class Props {
    dashboardConfig!: any;
    filterPreferences!: any;
}

@Options({
  computed: {
    ...mapState('dashboard', ['metricsData', 'contractlaborCalloutData', 'premiumLaborBreakdownByCL', 'aggregatedDashbaordFilter']),
    ...mapState('profile', ['profileData', 'appInsightEventData']),
  },
  components: {
    ToasterPopup,
    ContractLaborCalloutPopup,
    Actionable,
    StaffToForecastCensus,
    PremiumLaborBreakdown
  },
})
export default class DashboardMain extends Vue.with(Props) {

    //Data properties
    dashboardInterval!: any;
    metricsData!: any;
    contractlaborCalloutData!: any;
    premiumLaborBreakdownByCL!: any;
    aggregatedDashbaordFilter!: any;
    profileData!: Profile;
    dashboardData: any = []; 
    deptStats: any = [];
    currentNextShifts: any = [];
    contractLaborCount:number = 0;
    calloutCount: number = 0
    isContractLaborCalloutVisible:boolean = false;
    isActionableModelVisible:boolean = false;
    contractLaborCalloutDetails!: any;
    contractLaborCalloutFlags!: any;
    facilityConfigs: any = {};
    actionableData: any = {};
    staffToForecastData: any = {};
    appInsightEventData!:any ;
    maxCensusEntry: number = 32767;
    maxCensusValue: number = 999;
    nursingSkills: any[] = ['Chg RN', 'RN', 'LPN', 'Resource RN'];
    supportSkills: any[] = ['PCT', 'Clerical', 'Clinical Tech',	'Specialists', 'FAN/EVS'];
    defaults: any = {
        shortDateFormat: 'YYYY-MM-DD',
        shortDateFormatPresentation: 'MM/DD/YYYY',
        longDateTimeFormatPresentation: 'MM/DD/YYYY  hh:mm A',
        shortTimeFormat: 'hh:mm a',
        shiftCell: 'shiftCell',
        longDateFormat: 'YYYY-MM-DDTHH:mm:ss',
        cssClass: 'gridStandard',
        maxCensus: 999,
        appMode: 0,
        defaultFacilityId: 0,  
        contractLaborEnabled: false, 
        configFilters: {} as Filter,
        dashboardDepartmentIds: [] as any,
        currentShiftChecked: true,
        nextShiftChecked: false,
        previousShiftChecked: false,
        tenantId: '', 
        userId: 0, 
        dashboardId: "", 
        startDateTime: null,
        endDateTime: null,
        disableRefresh: false,
        refreshRate: 300000,
        showDate: false,
        plusMetricEnabled: false,
        AppUrl: ''
    };
    transactionInfoList: TransactionInfo[] = [];
    transactionInfo: TransactionInfo = {
        DashboardId: "",
        FacilityId: 0,
        FacilityName: null,
        FacilityCoid: null,
        ClusterId: null,
        ClusterName: null,
        DepartmentId: null,
        DepartmentName: null,
        DepartmentCode: null,
        DepartmentSummaryId: null,
        StartDateTime: null,
        EndDateTime: null,
        ActualCensus: null,
        ForecastCensus: null,
        StaffTo: null,
        Target: null,
        Scheduled: null,
        Note: null,
        SkillLevelId: undefined,
        SkillLevelDescription: undefined,
        PCSRangeName: null,
        PCSRating: null,
        PCSTotalCensus: null,
        PatientClassificationRatingId: null,
        PCSEnabled: null,
        LastCensusUpdateStartDateTime: null,
        PMEnabled: null,
        IsSkillAggregatedDifferenceZero: false,
        UnderStaffedSkills: null,
        AggregatedDashboard: false,
        FacilityPriorDatesEditable: 0,
        isGridUnderStaff: false,
        isGridNetZero: false,
        AllowManualClRequests: false
    }; 
    MetricsFilter: MetricsFilter = {
        DashboardId: "",
        StartDateTime: "",
        EndDateTime: "",
        CurrentDateTime: "",
        Facilities: [] as number[],
        Clusters: null,
        Departments: null,
        SortFields: null,
        UserId: null,
        CurrentShiftChecked: null,
        NextShiftChecked: null,
        PreviousShiftChecked: null,
      };
      msgValue!: string;
      classFlag!: string;
      isToasterVisible:boolean = false;
      isStafToForecastModelVisible:boolean = false;
      isPremiumLaborBreakdownVisible: boolean = false;
      isTrainingVideoVisible: boolean = false;
      premLaborBreakdownInput: PremiumLaborKeyValuePair[] = [];

    //Lifecycle hooks
    created(){
        this.emitter.on("refreshDashboard", this.refreshDashboard);
        this.emitter.on("displayContractLaborCalloutPopup", this.showContractLaborCalloutPopup);
        this.emitter.on("autoRefreshChanged", this.autoRefreshChanged);
        this.emitter.on("printAggregatedSkillDashboard", this.printAggregatedSkillDashboard);
        this.emitter.on("exportAggregatedSkillDashboardToExcel", this.exportAggregatedSkillDashboardToExcel);        
        this.emitter.on("displayTrainingVideo", this.displayTrainingVideo);

        useAppInsights().trackEvent({
            name: 'DASHBOARD_VIEW', properties:
                JSON.parse(JSON.stringify(this.appInsightEventData))
        });
    }
    mounted(){
        this.setDefaultProperties();
        this.getInitialDashboardConfig();
        this.defaults.appMode = this.appMode(this.dashboardConfig.AppMode);
        this.emitter.emit("loadSurgeBarData");
        this.emitter.emit("refreshNotificationCount");
        this.emitter.emit("loadCLPendingCount");
        this.emitter.emit("setSelectedTopNavTab", "AggregatedSkill");       
       
        
    }
    unmounted() {
        clearInterval(this.dashboardInterval);
        this.emitter.off("refreshDashboard", this.refreshDashboard);
        this.emitter.off("displayContractLaborCalloutPopup", this.showContractLaborCalloutPopup);
        this.emitter.off("autoRefreshChanged", this.autoRefreshChanged);
        this.emitter.off("printAggregatedSkillDashboard", this.printAggregatedSkillDashboard);
        this.emitter.off("exportAggregatedSkillDashboardToExcel", this.exportAggregatedSkillDashboardToExcel);
        this.emitter.emit("hideLastUpdatedTime");
        this.emitter.off("displayTrainingVideo", this.displayTrainingVideo); 
    }

    //Methods
    setDefaultProperties(){
        this.defaults.defaultFacilityId = this.profileData.FacilityId;
        this.defaults.contractLaborEnabled = this.profileData.ContractLaborEnabled; 
        this.defaults.tenantId = this.profileData.TenantId;
        this.defaults.userId = this.profileData.UserId;
        this.defaults.dashboardId = this.profileData.DashboardId;
        this.defaults.plusMetricEnabled = this.profileData.PlusMetricEnabled;
        this.defaults.AppUrl = this.profileData.AppUrl;
    }

    renderDashboardBody(): void {
      var facilities = JSON.parse(JSON.stringify(this.metricsData));

      var departmentList = [];
      this.contractLaborCount = 0;
      this.calloutCount = 0;
      this.deptStats = [];
      var that = this;       

      //Display Facility column data
      for (var f = 0; f < facilities.length; f++) {
        var facility: any = facilities[f];                   
        
        //@ts-ignore
        var clusters: any = jslinq(facility.Clusters).orderBy(function (item) { return item.ClusterName; }).toList();

        this.transactionInfo.DashboardId = this.defaults.dashboardId; 
        this.transactionInfo.FacilityId = facility.FacilityId;
        this.transactionInfo.FacilityName = facility.FacilityName;
        this.transactionInfo.FacilityCoid = facility.FacilityCoId;
        this.transactionInfo.PMEnabled = facility.IsIsasEnabled && this.defaults.plusMetricEnabled; 

        //Display Cluster column data
        for (var c = 0; c < clusters.length; c++) {
          var cluster: any = clusters[c];
          
          //@ts-ignore
          var departments = jslinq(cluster.Departments).orderBy(function (item) { return item.DepartmentCode; }).toList();

          this.transactionInfo.ClusterId = cluster.ClusterId;
          this.transactionInfo.ClusterName = cluster.ClusterName;

          //Display Department Name and Code column data
          for (var d = 0; d < departments.length; d++) {
            var department: any = departments[d];

            this.transactionInfo.DepartmentId = department.DepartmentId;
            this.transactionInfo.DepartmentCode = department.DepartmentCode;
            this.transactionInfo.DepartmentName = department.DepartmentName;

            departmentList.push(department.DepartmentId);

            //Display Shift Summary column data
            for (var s = 0; s < department.Summaries.length; s++) {
              var summary: any = department.Summaries[s];

              this.transactionInfo.DepartmentSummaryId = summary.DepartmentSummaryId;
              this.transactionInfo.StartDateTime = summary.StartDateTime;
              this.transactionInfo.EndDateTime = summary.EndDateTime;

              if (summary.Metrics.length > 0) {
                  this.transactionInfo.ActualCensus = summary.Metrics[0].ActualCensus;
                  this.transactionInfo.ForecastCensus = summary.Metrics[0].ForecastCensus;
                  this.transactionInfo.StaffTo = summary.Metrics[0].StaffTo;
                  this.transactionInfo.Scheduled = summary.Metrics[0].Scheduled.toFixed(2);
                  this.transactionInfo.Target = summary.Metrics[0].Target.toFixed(2);
                  this.transactionInfo.SkillLevelId = summary.Metrics[0].SkillLevelId;
                  this.transactionInfo.SkillLevelDescription = summary.Metrics[0].SkillLevelDescription;
                  this.transactionInfo.PCSRangeName = summary.Metrics[0].PCSRangeName;
                  this.transactionInfo.PCSRating = summary.Metrics[0].PCSRating ? summary.Metrics[0].PCSRating.toFixed(2) : null;
                  this.transactionInfo.PCSTotalCensus = summary.Metrics[0].PCSTotalCensus != null && summary.Metrics[0].PCSTotalCensus != undefined ? summary.Metrics[0].PCSTotalCensus.toFixed(2) : null;
                  this.transactionInfo.PatientClassificationRatingId = summary.Metrics[0].PatientClassificationRatingId;
                  this.transactionInfo.PCSEnabled = summary.Metrics[0].PCSEnabled;
                  this.transactionInfo.LastCensusUpdateStartDateTime = summary.Metrics[0].LastCensusUpdateStartDateTime;
                  this.transactionInfo.AllowManualClRequests = summary.Metrics[0].AllowManualClRequests;
                  summary.Metrics.forEach(function (metricItem: any) {
                      that.deptStats.push({
                          DepartmentId: metricItem.DepartmentId,
                          DeptSummaryId: metricItem.DeptSummaryId,
                          SkillLevelId: metricItem.SkillLevelId,
                          Target: metricItem.Target,
                          Scheduled: metricItem.Scheduled
                      });
                  });

                  summary.Metrics.forEach(function (metricItem: any) {
                      that.contractLaborCount += metricItem.ContractLaborCount;
                      that.calloutCount += metricItem.CalloutCount;
                  });
              }

              this.AddMetrics(summary.Metrics);
            }
          }
        } 
      }
        this.defaults.dashboardDepartmentIds = departmentList;
    }
    AddMetrics(metrics: any) {
      if (metrics.length > 0) {
          var dashboardConfig: any = this.dashboardConfig;
          var metricDataRowList: any = [];
          var underStaffSkills: any = [];

          //@ts-ignore
          var underStaffed= jslinq(metrics)
                  .where(function (metric: any) {
                      return metric.Difference < 0;
                  })
              .toList();

          underStaffed.forEach((metirc: any) => {
              underStaffSkills.push(metirc.SkillLevelId)
          });

          this.transactionInfo.UnderStaffedSkills = underStaffSkills;

          //@ts-ignore
          var skillGroupDashboard = jslinq(dashboardConfig.EntityModelsHeaders)
                            .where(function (metric: any) { 
                                return metric.RelationId == DashboardRelations.SKILLGROUPHEADER;
                            })
                        .toList();
          if (skillGroupDashboard.length > 0) {
              this.transactionInfo.AggregatedDashboard = true;
          }                    
          
          // Loop through metrics data columns to build table.
          for (var c = 5; c < dashboardConfig.EntityModelsHeaders.length; c++) {

              var columnHeader = dashboardConfig.EntityModelsHeaders[c];
              var columnData: any = undefined;
          
              if (columnHeader.RelationId == DashboardRelations.SKILLGROUPHEADER) { 
                  
                  //@ts-ignore
                  var skillsToExclude = jslinq(dashboardConfig.EntityModelsHeaders)
                      .where(function (item: any) {
                          return (item.RelationFieldName !== null
                              && item.RelationFieldName.toLowerCase() === "Difference".toLowerCase()
                              && item.RelationValue
                              && item.RelationId == DashboardRelations.SKILLLEVEL) 
                      })
                      .select(function (data: any) { return data.RelationValue })
                      .toList();

                  dashboardConfig.CustomSkillGroups.forEach((item: any) => {
                      skillsToExclude.push(item.SkillLevelId);
                  });
                  
                  columnData = { "Target": 0, "Scheduled": 0, "Difference": 0 };
                  if (columnHeader.RelationValue !== undefined) {

                      //@ts-ignore
                      var metricsBySkillCategory: any = jslinq(metrics)
                          .where(function (metric: any) {
                              return metric.SkillLevelCategoryId === columnHeader.RelationValue
                                  && skillsToExclude.indexOf(metric.SkillLevelId) == -1;
                          })
                          .toList();

                      var isSkillHasValue: boolean = false;
                      this.transactionInfo.IsSkillAggregatedDifferenceZero = false;

                      metricsBySkillCategory.forEach((metirc: any) => {
                          if (isSkillHasValue == false && metirc.Difference != 0) {
                              isSkillHasValue = true;
                          }
                          columnData.Target = columnData.Target + metirc.Target;
                          columnData.Scheduled = columnData.Scheduled + metirc.Scheduled;
                          columnData.Difference = columnData.Difference + metirc.Difference;
                      });

                      if (columnData.Difference == 0 && isSkillHasValue) {
                          this.transactionInfo.IsSkillAggregatedDifferenceZero = true;
                      }
                  }
              }

              else if (columnHeader.RelationId == 104 && columnHeader.RelationFieldName !== null && columnHeader.RelationFieldName.toLowerCase() === "Difference".toLowerCase()) {
                  columnData = { "Target": 0, "Scheduled": 0, "Difference": 0 };
                  var skillsToInclude: any = [];

                  dashboardConfig.CustomSkillGroups.forEach((item: any) => {
                    if (item.EntityId.toUpperCase() == columnHeader.EntityId.toUpperCase()) {
                      skillsToInclude.push(item.SkillLevelId);
                    }
                  });

                //@ts-ignore
                  var metricsBySkillCategories = jslinq(metrics)
                      .where(function (metric: any) {
                          return skillsToInclude.indexOf(metric.SkillLevelId) >= 0;
                      })
                      .toList();
                  var isSkillHasValues = false;
                  this.transactionInfo.IsSkillAggregatedDifferenceZero = false;

                  metricsBySkillCategories.forEach((metirc: any) => {
                    if (isSkillHasValues == false && metirc.Difference != 0) {
                          isSkillHasValues = true;
                      }
                      columnData.Target = columnData.Target + metirc.Target;
                      columnData.Scheduled = columnData.Scheduled + metirc.Scheduled;
                      columnData.Difference = columnData.Difference + metirc.Difference;
                  });

                  if (columnData.Difference == 0 && isSkillHasValues) {
                      this.transactionInfo.IsSkillAggregatedDifferenceZero = true;
                  }
              }

              else {
                  if (columnHeader.RelationValue !== undefined) {
                      //@ts-ignore
                      columnData = jslinq(metrics)
                          .firstOrDefault(function (metric: any) {
                              return metric.SkillLevelId === columnHeader.RelationValue;
                          });
                  }
              }

              var value: any = 0;
              var targetField: string = 'Target';

              this.transactionInfo.SkillLevelId = undefined;
              this.transactionInfo.SkillLevelDescription = undefined;

              switch (columnHeader.EntityName) {
                  case 'OT':
                      this.transactionInfo.SkillLevelId = columnHeader.RelationValue;
                      this.transactionInfo.SkillLevelDescription = columnHeader.EntityName;
                      break;

                  case 'C/L':
                      this.transactionInfo.SkillLevelId = columnHeader.RelationValue;
                      this.transactionInfo.SkillLevelDescription = columnHeader.EntityName;

                      break;
              }

              // set some defaults in case no data exists.
              if (columnHeader.RelationFieldName !== null && columnHeader.RelationFieldName.toLowerCase() === 'Difference'.toLowerCase()) {
                  this.transactionInfo.SkillLevelId = columnHeader.RelationValue;
                  this.transactionInfo.SkillLevelDescription = columnHeader.EntityName;
                  value = value.toFixed(2);
              }

              if (columnData !== null) {

                  value = columnData[columnHeader.RelationFieldName];

                  if (value > this.defaults.maxCensus) {
                      value = this.defaults.maxCensus;
                  }

                  if (columnHeader.RelationFieldName !== null && columnHeader.RelationFieldName.toLowerCase() === 'Difference'.toLowerCase()) {
                      this.transactionInfo.SkillLevelId = columnHeader.RelationValue;
                      this.transactionInfo.SkillLevelDescription = columnHeader.EntityName;
                      this.transactionInfo.Target = columnData[targetField].toFixed(2);
                      this.transactionInfo.Scheduled = columnData['Scheduled'].toFixed(2);

                      if (value < 0) {
                          this.transactionInfo.isGridUnderStaff= true;
                      }
                      else{
                          this.transactionInfo.isGridUnderStaff= false;
                      }

                      if (this.transactionInfo.IsSkillAggregatedDifferenceZero) {
                          this.transactionInfo.isGridNetZero = true;
                      }
                      else{
                          this.transactionInfo.isGridNetZero = false;
                      }
                  }
              }
              if (this.transactionInfo != null){
                this.transactionInfoList.push(JSON.parse(JSON.stringify(this.transactionInfo)));
              }
          }
      }
      return metricDataRowList;
    }
    getCurrentDateTime() {
      var date = moment(new Date()).format(this.defaults.longDateFormat);
      return date;
    }
    getStartDate() {
        var date = moment(new Date()).format(this.defaults.shortDateFormat);
        var sdt = date + 'T07:00:00';

        return sdt;
    }
    getEndDate() {
        var date = moment(new Date()).format(this.defaults.shortDateFormat);
        var edt = date + 'T23:59:00';

        return edt;
    }
    
    startDateTimeFormated(startDateTime: any) {
        return  moment(new Date(startDateTime)).format(this.defaults.longDateTimeFormatPresentation);
    }
    endDateTimeFormated(endDateTime: any) {
        return  moment(new Date(endDateTime)).format(this.defaults.longDateTimeFormatPresentation);
    }
    appMode(AppModeData: any): number {
      switch (AppModeData) {
        case 0:
            return AppMode.ReadOnly;
        case 1:
            return AppMode.ReadOnlyDetails;
        case 2:
            return AppMode.FullAccess;
        case undefined:
            return this.getAppMode();
        case null:
            return this.getAppMode();
      }
      return AppMode.ReadOnly;
    }

    getAppMode(): number
    {
        if(this.profileData.Permissions != null && this.profileData.Permissions.length > 0){
            if (this.profileData.Permissions.indexOf("Staffing Dashboard - Full Access") != -1)
            {
                return AppMode.FullAccess;
            }

            if (this.profileData.Permissions.indexOf("Staffing Dashboard - Details") != -1)
            {
                return AppMode.ReadOnlyDetails;
            }

            if (this.profileData.Permissions.indexOf("Staffing Dashboard - Read Only") != -1)
            {
                return AppMode.ReadOnly;
            }
        }
        else{
            return AppMode.NoAccess;
        }
        return AppMode.NoAccess;
    }

    showEditStaffToForecastCensus(deptSummaryId: any, startDateTime: any) {
        //@ts-ignore
        var data = jslinq(this.transactionInfoList)
                .where(function (item: any) {
                    if (item.DepartmentSummaryId == deptSummaryId && item.StartDateTime == startDateTime) {
                        return item;
                    }
                }).lastOrDefault();

        if (this.defaults.appMode === AppMode.ReadOnlyDetails || 
            this.defaults.appMode === AppMode.FullAccess) {

            this.emitter.emit('showLoader');
            this.staffToForecastData = {
                DataSet: data,
                Config: this.getFacilitiesFromResponse(),
                ShortDateFormat: this.defaults.shortDateFormat,
                ShortTimeFormat: this.defaults.shortTimeFormat,
                TenantId: this.defaults.tenantId,
                UserId: this.defaults.userId,
                ShortDateFormatPresentation: this.defaults.shortDateFormatPresentation,
                AppMode: this.defaults.appMode
            };
            this.isStafToForecastModelVisible = true

        }
    }
    showActionable(deptSummaryId: any, startDateTime: any, origin: any){
      //@ts-ignore
        var data = jslinq(this.transactionInfoList)
                .where(function (item: any) {
                    if (item.DepartmentSummaryId == deptSummaryId && item.StartDateTime == startDateTime) {
                        return item;
                    }
                }).lastOrDefault();

        if((this.defaults.appMode === AppMode.FullAccess || 
            this.defaults.appMode === AppMode.ReadOnlyDetails)
            && Object.prototype.hasOwnProperty.call(data, 'SkillLevelId')){ 
            this.emitter.emit('showLoader');
            //@ts-ignore
            var skillDeficitsCells =  jslinq(this.transactionInfoList)
                .where(function (item: any) {
                    if (item.DepartmentId == data.DepartmentId && item.isGridUnderStaff) {
                        return item;
                    }
                }).toList();
            
            //@ts-ignore
            var skillDeficitIds = jslinq(skillDeficitsCells)
                        .select(function (item: any) {
                            return item.SkillLevelId
                        }).toList();

            //@ts-ignore
            var aggregated = jslinq(skillDeficitsCells)
                        .select(function (item: any) {
                            return item.AggregatedDashboard
                        }).toList();

            var underStaffedSkillsIdNetZero: any = [];
            //@ts-ignore
            var skillNetZeroIds = jslinq(this.transactionInfoList)
                .where(function (item: any) {
                    if (item.DepartmentId == data.DepartmentId && item.isGridNetZero) {
                        if(item.UnderStaffedSkills!= null && item.UnderStaffedSkills.length > 0){
                            item.UnderStaffedSkills.forEach((id: any) => {
                                underStaffedSkillsIdNetZero.push(id);
                            })
                        }
                        return item;
                    }
                }).toList();  
            skillNetZeroIds = underStaffedSkillsIdNetZero;                

            if (aggregated.length > 0 && aggregated[0]) {
                var underStaffedSkillsId: any = [];
                //@ts-ignore
                skillDeficitIds = jslinq(skillDeficitsCells)
                        .select(function (item: any) {
                            if(item.UnderStaffedSkills!= null && item.UnderStaffedSkills.length > 0){
                                item.UnderStaffedSkills.forEach((id: any) => {
                                    underStaffedSkillsId.push(id);
                                })
                            }
                            return item;
                        }).toList();

                var result: any = [];
                underStaffedSkillsId.forEach(function (e: any) {
                    if (result.indexOf(e) == -1) result.push(e);
                });
                skillDeficitIds = result;
            }
            else if (skillNetZeroIds.length > 0) {
                var result: any = [];
                skillNetZeroIds.forEach(function (e) {
                    if (result.indexOf(e) == -1) result.push(e);
                });
                skillDeficitIds = result;
            }

            this.actionableData = {
                DataSet: data,
                Config: this.getFacilitiesFromResponse(),
                Origin: origin,
                ShortDateFormat: this.defaults.shortDateFormat,
                ShortTimeFormat: this.defaults.shortTimeFormat,
                TenantId: this.defaults.tenantId,
                UserId: this.defaults.userId,
                ShortDateFormatPresentation: this.defaults.shortDateFormatPresentation,
                DeptStats: this.deptStats,
                AppMode: this.defaults.appMode,
                SkillDeficitIds: skillDeficitIds,
                ContractLaborEnabled: this.defaults.contractLaborEnabled,
                DashboardConfig: this.dashboardConfig,
                FacilityConfig: this.getFacilityConfigByFacilityId(data.FacilityId),
                LongDateTimeFormatPresentation: this.defaults.longDateTimeFormatPresentation,
                AllowManualClRequests: data.AllowManualClRequests
            }
            this.emitter.emit('hideLoader');
            this.isActionableModelVisible = true;
        }
        else{
            return;
        }
    }

    async loadPremiumLaborBreakdownPopupOnCLClick(deptSummaryId: any, startDateTime: any){
        //@ts-ignore
        var data = jslinq(this.transactionInfoList)
                .where(function (item: any) {
                    if (item.DepartmentSummaryId == deptSummaryId && item.StartDateTime == startDateTime) {
                        return item;
                    }
                }).lastOrDefault();

        if(data != null){
            var depts: any[] = [];
            depts.push(data.DepartmentId);

            var startTime = moment(data.StartDateTime).format("HH:mm");
            var endTime = moment(data.EndDateTime).format("HH:mm");
            var sDate = moment(data.StartDateTime).format(this.defaults.shortDateFormat);
            var eDate = moment(data.EndDateTime).format(this.defaults.shortDateFormat);
            
            var input: any = {
                DashboardId: data.DashboardId,
                TenantId: this.defaults.tenantId,
                StartDate: sDate,
                EndDate: eDate,
                startTime: startTime,
                endTime: endTime,
                Departments: depts
            }

            await this.$store
              .dispatch("dashboard/getPremiumLaborBreakdownByCL", input)
              .then(() => {
                    var premiumLaborBreakDown: PremiumLaborKeyValuePair[] = [];
                    if(this.premiumLaborBreakdownByCL != null && this.premiumLaborBreakdownByCL.length > 0){
                        this.premiumLaborBreakdownByCL.forEach((item: any) => {
                            var keypairTotal: PremiumLaborKeyValuePair = { Key: "", Value: 0, Name: "", LastCancelled: null, IsHeadingRow: false };
                            //load Net count Row
                            keypairTotal.Key = item.EmployeeType;
                            keypairTotal.Value = item.Value;
                            keypairTotal.IsHeadingRow = true;
                            premiumLaborBreakDown.push(keypairTotal);
                        })

                        this.premLaborBreakdownInput = premiumLaborBreakDown;
                        this.isPremiumLaborBreakdownVisible = true;
                    }
                    else{
                        this.msgValue = "No premium labor data available for selected department."
                        this.classFlag = "error";
                        this.isToasterVisible = true;
                    }
              })
              .catch((err: any) => {
                  if (err) {
                      this.emitter.emit('hideLoader');
                      this.msgValue = "Error encountered while retrieving premium labor breakdown details.";
                        this.classFlag = "error";
                        this.isToasterVisible = true;
                      console.error(err); // Handle errors any way you want
                  }
              });
        }
    }

    closePremiumLaborBreakdownPopup(){
        this.isPremiumLaborBreakdownVisible = false;
    }

    displayTrainingVideo(){
        this.isTrainingVideoVisible = true;
    }


    closeActionablePopup(){
        this.isActionableModelVisible = false;
        this.emitter.emit('showLoader');
        this.refreshDashboard();
    }

    closeStaffToForecastPopup(){
        this.isStafToForecastModelVisible = false;
        this.emitter.emit('showLoader');
        this.refreshDashboard();
    }

    getFacilitiesFromResponse() {

        var configs = this.defaults.configFilters;
        var facs = configs.Facilities;

        //@ts-ignore
        var facilityClusterDeptlData: any = jslinq(facs)
            .select((f: any) =>  {
                f.Clusters = [];
                //@ts-ignore
                f.Clusters = jslinq(configs.Clusters)
                    .where((c: any) => {
                        if (f.Value === c.ParentValue) {
                            return c;
                        }
                    }) 
                    .select((c: any) =>  {
                        c.Departments = [];
                        //@ts-ignore
                        c.Departments = jslinq(configs.Departments)
                                    .where((d: any) => {
                                        if (c.Value === d.ParentValue) {
                                            return d;
                                        }
                                    }) 
                                    .toList();
                        return c;
                    })
                    .toList();
                return f;
            })
            .toList();

        return facilityClusterDeptlData;
    }
    refreshDashboard(){
      this.setMetricsFilter();
      this.getMetricsDetails();
    }
    closeToasterPopup() {
      this.isToasterVisible = false;
    }
    async getInitialDashboardConfig(){
        if(this.dashboardConfig != null){
            this.defaults.configFilters = this.dashboardConfig.Filters;
            this.setStartEndDateTime();
            
            var currentShift: any = this.currentNextShifts[0];
            this.defaults.startDateTime = currentShift.startDateTime;
            this.defaults.endDateTime = currentShift.endDateTime;

            this.setMetricsFilter();

            this.getMetricsDetails();
            this.metricsDataAutoRefresh();
        }
    }
    async getMetricsDetails(){
        await this.$store
              .dispatch("dashboard/getMetricsDetails", this.setOverMidnight(this.MetricsFilter))
              .then(() => {
                this.dashboardData = JSON.parse(JSON.stringify(this.metricsData));
                this.emitter.emit('hideLoader');
                this.renderDashboardBody();
                this.setFilterBarData();
                this.createFacilityConfig();
                this.emitter.emit("setLastUpdateDateOfMetricsRefreshed");                
              })
              .catch((err: any) => {
                  if (err) {
                      this.emitter.emit('hideLoader');
                      this.msgValue = "Error encountered while retrieving metrics details.";
                        this.classFlag = "error";
                        this.isToasterVisible = true;
                      console.error(err); // Handle errors any way you want
                  }
              }).finally(() => {
                this.emitter.emit("showLastUpdatedTime");
              }); 
    }

    getSummaryTime(startDateTime: any){
        return moment(startDateTime).format(this.defaults.shortTimeFormat); 
    }

    getStableCount(metrics: any){
        var currentDateTime: any = new Date(this.getCurrentDateTime());
        var nextDayDateTime: any = moment(currentDateTime).clone().add(1, 'days');
        var value;
        if (new Date(metrics[0].StartDateTime) <= nextDayDateTime
                    && new Date(metrics[0].EndDateTime) > currentDateTime
                    && metrics[0].StableCount != null) {
            value = metrics[0].StableCount;
        }
        else {
            value = "-";
        }
        return value;
    }

    getUnstableCount(metrics: any){
        var currentDateTime: any = new Date(this.getCurrentDateTime());
        var nextDayDateTime: any = moment(currentDateTime).clone().add(1, 'days');
        var value;
        if (new Date(metrics[0].StartDateTime) <= nextDayDateTime
            && new Date(metrics[0].EndDateTime) > currentDateTime
            && metrics[0].UnstableCount != null) {
            value = metrics[0].UnstableCount;
        }
        else {
            value = "-";
        }
        return value;
    }

    getRNExpLess1YrTitle(metrics: any){
        return 'Experience Level < 1 Year: ' + metrics.RNExperienceLt1
                + '\rScheduled Count: ' + metrics.RNExperienceTotNurseCount.toFixed(2)
                + '\rScheduled %: ' + (metrics.RNExperienceLt1ThPct * 100).toFixed(1) + '% '
                + '\rThreshold %: ' + (metrics.RNExperienceLt1Th * 100).toFixed(1) + '%';
    }

    getRNExpLess1YrCss(metrics: any){
        var className: any = "";
        if (metrics.RNExperienceLt1 > 0 && metrics.RNExperienceLt1ThPct > metrics.RNExperienceLt1Th) {
            className = ' overstaffed';
        }        
        return className;
    }

    getOverTimeCalculation(metrics: any ){
        var overTime = 0;
        //@ts-ignore
        overTime = jslinq(metrics)
                .max(function(el: any){
                    return el.OTShiftsByDepartment;
                });
        return overTime;
    }

    getRNMinMax(metrics: any ){
        var text = "0 - 0";
        metrics.forEach((metricItem: any) => {
            if(metricItem.SkillLevelDesc.toLowerCase() == "rn")
                text = metricItem.TargetCensusLow + ' - ' + (metricItem.TargetCensusPeak > this.defaults.maxCensus ? this.defaults.maxCensus : metricItem.TargetCensusPeak);
        });
        return text;
    }

    getOverTimeCalculationCSS(metrics: any ){
        var overTime = 0, totalScheduled = 0, totalTarget = 0;
        var className: any = "";
        
        //@ts-ignore
        overTime = jslinq(metrics)
                .max(function(el: any){
                    return el.OTShiftsByDepartment;
                });

        //@ts-ignore
        totalScheduled = jslinq(metrics)
            .sum(function(el: any){
                return el.Scheduled;
        });

        //@ts-ignore
        totalTarget = jslinq(metrics)
            .sum(function(el: any){
                return el.Target;
        });

        if (overTime > 0 && totalScheduled > totalTarget) {
            className = ' overstaffed';
        }
        return className;
    }

    getCLOverTimeCalculationCSS(metrics: any ){
        var clCount = 0, totalScheduled = 0, totalTarget = 0;
        var className: any = "";       
        if(metrics.length >0)
        {
            clCount =  metrics[0].StaffCountsByPL;
        }
         //@ts-ignore
         totalScheduled = jslinq(metrics)
            .sum(function(el: any){
                return el.Scheduled;
        });

        //@ts-ignore
        totalTarget = jslinq(metrics)
            .sum(function(el: any){
                return el.Target;
        });       

        if (clCount > 0 && totalScheduled > totalTarget) {
            className = ' overstaffed';
        }
        return className;
    }
    
    showToasterPopup(data: any){
        this.msgValue = data.message;
        this.classFlag = data.flag;
        this.isToasterVisible = true;
    }
    getOverTimeCalculationTitle(metrics: any ){ 
        var overTime = 0, totalScheduled = 0, totalTarget = 0;
        
        //@ts-ignore
        overTime = jslinq(metrics)
                .max(function(el: any){
                    return el.OTShiftsByDepartment;
                });

        //@ts-ignore
        totalScheduled = jslinq(metrics)
            .sum(function(el: any){
                return el.Scheduled;
        });

        //@ts-ignore
        totalTarget = jslinq(metrics)
            .sum(function(el: any){
                return el.Target;
        });

        if (overTime > 0 && totalScheduled > totalTarget) {
            return 'Overall Target: ' + totalTarget.toFixed(2)
                + '\rOverall Scheduled: ' + totalScheduled.toFixed(2)
        }
    }

    getValueBySkillGroup(skill: string, metrics: any){ 
        var dashboardConfig: any = this.dashboardConfig;
        var columnData: any = undefined;
        var columnHeader: any = undefined, metricsValue: any = 0;
        var skillsToExclude: any = [], metricsBySkillCategory: any = [], skillsToInclude: any = [];

        //@ts-ignore
        columnHeader = jslinq(dashboardConfig.EntityModelsHeaders)
                        .where(function (item: any) {
                          return (item.EntityName == skill
                              ) 
                      }).firstOrDefault();

        if(skill == 'Chg RN'){
            columnData = { "Target": 0, "Scheduled": 0, "Difference": 0 };
            dashboardConfig.CustomSkillGroups.forEach((item: any) => {
                if (item.EntityId.toUpperCase() == columnHeader.EntityId.toUpperCase()) {
                    skillsToInclude.push(item.SkillLevelId);
                }
                });

            //@ts-ignore
            metricsBySkillCategory = jslinq(metrics)
                .where(function (metric: any) {
                    return skillsToInclude.indexOf(metric.SkillLevelId) >= 0;
                })
                .toList();

            metricsBySkillCategory.forEach((metirc: any) => {
                columnData.Target = columnData.Target + metirc.Target;
                columnData.Scheduled = columnData.Scheduled + metirc.Scheduled;
                columnData.Difference = columnData.Difference + metirc.Difference;
            });
        }
        else if(skill == 'Resource RN'){
            if (columnHeader.RelationValue !== undefined) {
                      //@ts-ignore
                      columnData = jslinq(metrics)
                          .firstOrDefault(function (metric: any) {
                              return metric.SkillLevelId === columnHeader.RelationValue;
                          });
                  }
        }
        else{
            columnData = { "Target": 0, "Scheduled": 0, "Difference": 0 };
            //@ts-ignore
            skillsToExclude = jslinq(dashboardConfig.EntityModelsHeaders)
                        .where(function (item: any) {
                            return (item.RelationFieldName === "Difference"
                                && item.RelationValue
                                && item.RelationId == DashboardRelations.SKILLLEVEL) 
                        })
                        .select(function (data: any) { return data.RelationValue })
                        .toList();

            dashboardConfig.CustomSkillGroups.forEach((item: any) => {
                skillsToExclude.push(item.SkillLevelId);
            });

            //@ts-ignore
            metricsBySkillCategory = jslinq(metrics)
                .where(function (metric: any) {
                    return metric.SkillLevelCategoryId === columnHeader.RelationValue
                        && skillsToExclude.indexOf(metric.SkillLevelId) == -1;
                })
                .toList();

            metricsBySkillCategory.forEach((metirc: any) => {
                columnData.Target = columnData.Target + metirc.Target;
                columnData.Scheduled = columnData.Scheduled + metirc.Scheduled;
                columnData.Difference = columnData.Difference + metirc.Difference;
            });
        }

        if (columnData !== null) {
            metricsValue = columnData[columnHeader.RelationFieldName];

            if (metricsValue > this.defaults.maxCensus) {
                metricsValue = this.defaults.maxCensus;
            }

            if (columnHeader.RelationFieldName === 'Difference') {
                metricsValue = metricsValue.toFixed(2);

                if (metricsValue > 0) {
                    metricsValue = '+' + metricsValue;
                }
            }
        }
        else{
            metricsValue = metricsValue.toFixed(2); 
        }

        return metricsValue;
    }

    getValueBySkillGroupCss(skill: string, metrics: any){
        var dashboardConfig: any = this.dashboardConfig;
        var columnData: any = undefined;
        var columnHeader: any = undefined, metricsValue: any = 0;
        var cssClass: string = "", skillsToExclude: any = [], metricsBySkillCategory: any = [], skillsToInclude: any = [];
        var isSkillAggregatedDifferenceZero: boolean = false, isSkillHasValue: boolean = false;

        //@ts-ignore
        columnHeader = jslinq(dashboardConfig.EntityModelsHeaders)
                        .where(function (item: any) {
                          return (item.EntityName == skill
                              ) 
                      }).firstOrDefault();

        if(skill == 'Chg RN'){
            columnData = { "Target": 0, "Scheduled": 0, "Difference": 0 };
            dashboardConfig.CustomSkillGroups.forEach((item: any) => {
                if (item.EntityId.toUpperCase() == columnHeader.EntityId.toUpperCase()) {
                    skillsToInclude.push(item.SkillLevelId);
                }
                });

            //@ts-ignore
            metricsBySkillCategory = jslinq(metrics)
                .where(function (metric: any) {
                    return skillsToInclude.indexOf(metric.SkillLevelId) >= 0;
                })
                .toList();

            metricsBySkillCategory.forEach((metirc: any) => {
                if (isSkillHasValue == false && metirc.Difference != 0) {
                    isSkillHasValue = true;
                }
                columnData.Target = columnData.Target + metirc.Target;
                columnData.Scheduled = columnData.Scheduled + metirc.Scheduled;
                columnData.Difference = columnData.Difference + metirc.Difference;
            });

            if (columnData.Difference == 0 && isSkillHasValue) {
                isSkillAggregatedDifferenceZero = true;
            }
        }
        else if(skill == 'Resource RN'){
            if (columnHeader.RelationValue !== undefined) {
                      //@ts-ignore
                      columnData = jslinq(metrics)
                          .firstOrDefault(function (metric: any) {
                              return metric.SkillLevelId === columnHeader.RelationValue;
                          });
                  }
        }
        else{
            columnData = { "Target": 0, "Scheduled": 0, "Difference": 0 };
            //@ts-ignore
            skillsToExclude = jslinq(dashboardConfig.EntityModelsHeaders)
                        .where(function (item: any) {
                            return (item.RelationFieldName === "Difference"
                                && item.RelationValue
                                && item.RelationId == DashboardRelations.SKILLLEVEL) 
                        })
                        .select(function (data: any) { return data.RelationValue })
                        .toList();

            dashboardConfig.CustomSkillGroups.forEach((item: any) => {
                skillsToExclude.push(item.SkillLevelId);
            });

            //@ts-ignore
            metricsBySkillCategory = jslinq(metrics)
                .where(function (metric: any) {
                    return metric.SkillLevelCategoryId === columnHeader.RelationValue
                        && skillsToExclude.indexOf(metric.SkillLevelId) == -1;
                })
                .toList();

            metricsBySkillCategory.forEach((metirc: any) => {
                if (isSkillHasValue == false && metirc.Difference != 0) {
                    isSkillHasValue = true;
                }
                columnData.Target = columnData.Target + metirc.Target;
                columnData.Scheduled = columnData.Scheduled + metirc.Scheduled;
                columnData.Difference = columnData.Difference + metirc.Difference;
            });

            if (columnData.Difference == 0 && isSkillHasValue) {
                isSkillAggregatedDifferenceZero = true;
            }
        }
        
        if (columnData !== null) {
            metricsValue = columnData[columnHeader.RelationFieldName];

            if (metricsValue > this.defaults.maxCensus) {
                metricsValue = this.defaults.maxCensus;
            }

            if (columnHeader.RelationFieldName === 'Difference') {

                cssClass = ' staff-scheduled';

                if (metricsValue < 0) {
                    cssClass = ' gridUnderStaff';
                }

                if (metricsValue > 0) {
                    cssClass = ' gridOverStaff';
                }

                if (isSkillAggregatedDifferenceZero) {
                    cssClass = ' gridNetZero'
                }
            }
        }
        return cssClass;
    }

    getValueBySkillGroupTitle(skill: string, metrics: any){
        var dashboardConfig: any = this.dashboardConfig;
        var columnData: any = undefined; 
        var columnHeader: any = undefined, title: string = "";
        var skillsToExclude: any = [], metricsBySkillCategory: any = [], skillsToInclude: any = [];

        //@ts-ignore
        columnHeader = jslinq(dashboardConfig.EntityModelsHeaders)
                        .where(function (item: any) {
                          return (item.EntityName == skill
                              ) 
                      }).firstOrDefault();

        if(skill == 'Chg RN'){
            columnData = { "Target": 0, "Scheduled": 0, "Difference": 0 };
            dashboardConfig.CustomSkillGroups.forEach((item: any) => {
                if (item.EntityId.toUpperCase() == columnHeader.EntityId.toUpperCase()) {
                    skillsToInclude.push(item.SkillLevelId);
                }
                });

            //@ts-ignore
            metricsBySkillCategory = jslinq(metrics)
                .where(function (metric: any) {
                    return skillsToInclude.indexOf(metric.SkillLevelId) >= 0;
                })
                .toList();

            metricsBySkillCategory.forEach((metirc: any) => {
                columnData.Target = columnData.Target + metirc.Target;
                columnData.Scheduled = columnData.Scheduled + metirc.Scheduled;
                columnData.Difference = columnData.Difference + metirc.Difference;
            });
        }
        else if(skill == 'Resource RN'){
            if (columnHeader.RelationValue !== undefined) {
                      //@ts-ignore
                      columnData = jslinq(metrics)
                          .firstOrDefault(function (metric: any) {
                              return metric.SkillLevelId === columnHeader.RelationValue;
                          });
                  }
        }
        else{
            columnData = { "Target": 0, "Scheduled": 0, "Difference": 0 };
            //@ts-ignore
            skillsToExclude = jslinq(dashboardConfig.EntityModelsHeaders)
                        .where(function (item: any) {
                            return (item.RelationFieldName === "Difference"
                                && item.RelationValue
                                && item.RelationId == DashboardRelations.SKILLLEVEL) 
                        })
                        .select(function (data: any) { return data.RelationValue })
                        .toList();

            dashboardConfig.CustomSkillGroups.forEach((item: any) => {
                skillsToExclude.push(item.SkillLevelId);
            });

            //@ts-ignore
            metricsBySkillCategory = jslinq(metrics)
                .where(function (metric: any) {
                    return metric.SkillLevelCategoryId === columnHeader.RelationValue
                        && skillsToExclude.indexOf(metric.SkillLevelId) == -1;
                })
                .toList();

            metricsBySkillCategory.forEach((metirc: any) => {
                columnData.Target = columnData.Target + metirc.Target;
                columnData.Scheduled = columnData.Scheduled + metirc.Scheduled;
                columnData.Difference = columnData.Difference + metirc.Difference;
            });
        }

        if (columnData !== null) {
            if (columnHeader.RelationFieldName === 'Difference') {
                title = 'Target: ' + columnData['Target'].toFixed(2)
                        + '\rScheduled: ' + columnData['Scheduled'].toFixed(2);
            }
        }
        else{
            title = '*No Data*';
        }
        return title;
    }

    createFacilityConfig() {
        var that = this;
        this.metricsData.forEach(function (facility: any) {
            that.addFacilityConfig(facility.FacilityId, {
                FacilityPriorDatesEditable: facility.FacilityPriorDatesEditable
            });
        })
    }
    addFacilityConfig(facilityId: any, options: any) {
        if (this.facilityConfigs[facilityId]) {
            return this.facilityConfigs[facilityId];
        }

        this.facilityConfigs[facilityId] = options;
    }

    getFacilityConfigByFacilityId(facilityId: any) {
        return this.facilityConfigs[facilityId];
    }

    setMetricsFilter(){
        let filterToApply: any =  null;
        if(this.aggregatedDashbaordFilter != null){
            filterToApply = this.aggregatedDashbaordFilter;
        }
        
        if(filterToApply == null){
            var facilityIds = this.getCollectionIds(this.defaults.configFilters.Facilities);
            
            var defaultFacilityId = [];
            if (this.defaults.defaultFacilityId != null && this.defaults.defaultFacilityId != undefined && this.defaults.defaultFacilityId != 0) {
                defaultFacilityId.push(this.defaults.defaultFacilityId);
            }
            else {
                defaultFacilityId.push(facilityIds[0]);
            }

            this.MetricsFilter.DashboardId = this.defaults.dashboardId;
            this.MetricsFilter.StartDateTime = this.defaults.startDateTime;
            this.MetricsFilter.EndDateTime = this.defaults.endDateTime;
            this.MetricsFilter.Facilities = defaultFacilityId;
            this.MetricsFilter.Clusters = null;
            this.MetricsFilter.Departments = null;
            this.MetricsFilter.SortFields = null;
            this.MetricsFilter.UserId = null;
            this.MetricsFilter.CurrentShiftChecked = this.defaults.currentShiftChecked;
            this.MetricsFilter.NextShiftChecked = this.defaults.nextShiftChecked;
            this.MetricsFilter.PreviousShiftChecked = this.defaults.previousShiftChecked;
            this.MetricsFilter.CurrentDateTime = this.getCurrentDateTime();

            if(this.filterPreferences != null && this.filterPreferences.length > 0){
                //@ts-ignore
                var defaultfilterPreference: any = jslinq(this.filterPreferences)
                        .where(function (filter: any) {
                            if(filter.isDefault){
                            return filter;
                            }
                        })
                    .toList();

                this.MetricsFilter.Facilities = this.getIntCollection(defaultfilterPreference[0].facilities, this.defaults.configFilters.Facilities);
                this.MetricsFilter.Clusters = this.getIntCollection(defaultfilterPreference[0].serviceLines, this.defaults.configFilters.Clusters, true);
                this.MetricsFilter.Departments = this.getIntCollection(defaultfilterPreference[0].departments, this.defaults.configFilters.Departments, true);
            }
        }
        else{
            this.MetricsFilter.DashboardId = this.defaults.dashboardId;
            this.MetricsFilter.StartDateTime = filterToApply.StartDateTime;
            this.MetricsFilter.EndDateTime = filterToApply.EndDateTime;
            this.MetricsFilter.Facilities = filterToApply.Facilities;
            this.MetricsFilter.Clusters = filterToApply.Clusters;
            this.MetricsFilter.Departments = filterToApply.Departments;
            this.MetricsFilter.SortFields = filterToApply.SortFields;
            this.MetricsFilter.UserId = filterToApply.UserId;
            this.MetricsFilter.CurrentShiftChecked = filterToApply.CurrentShiftChecked;
            this.MetricsFilter.NextShiftChecked = filterToApply.NextShiftChecked;
            this.MetricsFilter.PreviousShiftChecked = filterToApply.PreviousShiftChecked;
            this.MetricsFilter.CurrentDateTime = this.getCurrentDateTime();
        }
        
        if (this.MetricsFilter.StartDateTime !== null &&
            this.MetricsFilter.EndDateTime !== null &&
            this.MetricsFilter.Facilities !== null) {

            if (this.MetricsFilter.CurrentShiftChecked === true && this.MetricsFilter.NextShiftChecked === true && this.MetricsFilter.PreviousShiftChecked === true) {
                this.setStartEndDateTime();
                if (this.currentNextShifts[1] == null && this.currentNextShifts[2] == null) {
                    this.MetricsFilter.StartDateTime = this.currentNextShifts[0].startDateTime;
                    this.MetricsFilter.EndDateTime = this.currentNextShifts[0].endDateTime;
                }
                else if (this.currentNextShifts[1] == null) {
                    this.MetricsFilter.StartDateTime = this.currentNextShifts[2].startDateTime;
                    this.MetricsFilter.EndDateTime = this.currentNextShifts[0].endDateTime;
                }
                else if (this.currentNextShifts[2] == null) {
                    this.MetricsFilter.StartDateTime = this.currentNextShifts[0].startDateTime;
                    this.MetricsFilter.EndDateTime = this.currentNextShifts[1].endDateTime;
                }
                else {
                    this.MetricsFilter.StartDateTime = this.currentNextShifts[2].startDateTime;
                    this.MetricsFilter.EndDateTime = this.currentNextShifts[1].endDateTime;
                }
            }


            else if (this.MetricsFilter.CurrentShiftChecked === true && this.MetricsFilter.NextShiftChecked === true) {
                this.setStartEndDateTime();
                if (this.currentNextShifts[1] == null) {
                    this.MetricsFilter.StartDateTime = this.currentNextShifts[0].startDateTime;
                    this.MetricsFilter.EndDateTime = this.currentNextShifts[0].endDateTime;
                }
                else {
                    this.MetricsFilter.StartDateTime = this.currentNextShifts[0].startDateTime;
                    this.MetricsFilter.EndDateTime = this.currentNextShifts[1].endDateTime;
                }
            }

            else if (this.MetricsFilter.NextShiftChecked === true && this.MetricsFilter.PreviousShiftChecked === true) {
                this.setStartEndDateTime();
                if (this.currentNextShifts[1] == null && this.currentNextShifts[2] == null) {
                    this.MetricsFilter.StartDateTime = this.currentNextShifts[0].startDateTime;
                    this.MetricsFilter.EndDateTime = this.currentNextShifts[0].endDateTime;
                }
                else if (this.currentNextShifts[1] == null) {
                    this.MetricsFilter.StartDateTime = this.currentNextShifts[2].startDateTime;
                    this.MetricsFilter.EndDateTime = this.currentNextShifts[2].endDateTime;
                }
                else if (this.currentNextShifts[2] == null) {
                    this.MetricsFilter.StartDateTime = this.currentNextShifts[1].startDateTime;
                    this.MetricsFilter.EndDateTime = this.currentNextShifts[1].endDateTime;
                }
                else {
                    this.MetricsFilter.StartDateTime = this.currentNextShifts[2].startDateTime;
                    this.MetricsFilter.EndDateTime = this.currentNextShifts[1].endDateTime;
                }
            }

            else if (this.MetricsFilter.CurrentShiftChecked === true && this.MetricsFilter.PreviousShiftChecked === true) {
                this.setStartEndDateTime();
                if (this.currentNextShifts[2] == null) {
                    this.MetricsFilter.StartDateTime = this.currentNextShifts[0].startDateTime;
                    this.MetricsFilter.EndDateTime = this.currentNextShifts[0].endDateTime;
                }
                else {
                    this.MetricsFilter.StartDateTime = this.currentNextShifts[2].startDateTime;
                    this.MetricsFilter.EndDateTime = this.currentNextShifts[0].endDateTime;
                }
            }


            else if (this.MetricsFilter.CurrentShiftChecked === true) {
                this.setStartEndDateTime();
                this.MetricsFilter.StartDateTime = this.currentNextShifts[0].startDateTime;
                this.MetricsFilter.EndDateTime = this.currentNextShifts[0].endDateTime;
            }

            else if (this.MetricsFilter.NextShiftChecked === true) {
                this.setStartEndDateTime();
                if (this.currentNextShifts[1] == null) {
                    this.MetricsFilter.StartDateTime = this.currentNextShifts[0].startDateTime;
                    this.MetricsFilter.EndDateTime = this.currentNextShifts[0].endDateTime;
                }
                else {
                    this.MetricsFilter.StartDateTime = this.currentNextShifts[1].startDateTime;
                    this.MetricsFilter.EndDateTime = this.currentNextShifts[1].endDateTime;
                }
            }

            else if (this.MetricsFilter.PreviousShiftChecked === true) {
                this.setStartEndDateTime();
                if (this.currentNextShifts[2] == null) {
                    this.MetricsFilter.StartDateTime = this.currentNextShifts[0].startDateTime;
                    this.MetricsFilter.EndDateTime = this.currentNextShifts[0].endDateTime;
                }
                else {
                    this.MetricsFilter.StartDateTime = this.currentNextShifts[2].startDateTime;
                    this.MetricsFilter.EndDateTime = this.currentNextShifts[2].endDateTime;
                }
            }

            this.defaults.startDateTime = this.MetricsFilter.StartDateTime;
            this.defaults.startDateTime = this.MetricsFilter.EndDateTime;
        }
    }

    getCollectionIds = function (collection: any) {
        var items = [];

        for (var i = 0; i < collection.length; i++) {
            var obj = collection[i];
            var it = { ParentValue: obj.ParentValue, Value: obj.Value, Text: obj.Text };
            items.push(it);
        }

        //@ts-ignore
        var result = jslinq(items)
            .select(function (item) {
                return item.Value;
            })
            .toList();

        return result;
    };

    setOverMidnight(filters: any) {
        var startDateTime = new Date(filters.StartDateTime);
        var endDateTime = new Date(filters.EndDateTime);

        //24 hours period 
        var nextDay = moment(startDateTime, 'MM-DD-YYYY hh:mm a');
        nextDay.add(1, 'd');
        nextDay.subtract(1, 'm');
        var nextDayDate = nextDay.toDate();

        if (endDateTime > nextDayDate) {
            filters.EndDateTime = moment(nextDayDate, this.defaults.longDateFormat);
        }

        return filters;
    }
    getIntCollection(filters: any, collection: any, allowNull?: any) {
        var items = [];
        if (filters === undefined || filters === null || filters.length === 0) {
            if (allowNull) {
                return null;
            }
            //@ts-ignore
            items = jslinq(collection)
                .select(function (collection: any) {
                    return collection.Value;
                })
                .toList();
        } else {
            items = filters;
        }
        return items;
    }

    setStartEndDateTime() {
        var distinctSummary = this.getDistinct(this.defaults.configFilters.Summaries);

        this.currentNextShifts = this.getCurrentNextShift(distinctSummary);

        return distinctSummary;

    }
    getDistinct(collection: KeyPairModel[]) {
        //@ts-ignore
        var results = jslinq(collection)
            .select(function (item: any) {
                return { Value: item.Value, Text: item.Text };
            })
            .distinct()
            .orderBy(function (item: any) {
                return item.Value;
            })
            .toList();

        return results;
    }
    getCurrentNextShift(collection: KeyPairModel[]) {
        var currentDateTime: any =  null;
        var currentDT: any = null;
        
        currentDT = this.getCurrentDateTime();
        currentDateTime = new Date(currentDT);
        var currentNextShift = [];

        //@ts-ignore
        var results: any = jslinq(collection)
            .select(function (item) {
                var model:StartEndDateTimeModel = {
                    startDateTime: null,
                    endDateTime: null,
                    total: null
                };
                var datetimes = item.Value.split(' - ');
                var startDateTime = new Date(datetimes[0]);
                var endDateTime = new Date(datetimes[1]);

                model.startDateTime = datetimes[0];
                model.endDateTime = datetimes[1];
                model.total = endDateTime.getTime() - startDateTime.getTime();

                return model;
            }).toList();

        //@ts-ignore
        var current: any= jslinq(results)
            .where(function (item: any) {
                var startDateTime = new Date(item.startDateTime);
                var endDateTime = new Date(item.endDateTime);

                if ((currentDateTime.getTime() >= startDateTime.getTime()) && (currentDateTime.getTime() <= endDateTime.getTime())) {
                    return item;
                }
            }).toList();

        //@ts-ignore
        var minShift = jslinq(current)
            .min(function (el: any) {
                return el.total;
            });

        //@ts-ignore
        current = jslinq(current)
            .firstOrDefault(function (item: any) {
                return item.total === minShift;
            });

        if (current === null) {
            var startEndModel: StartEndDateTimeModel = {
                    startDateTime: null,
                    endDateTime: null,
                    total: null
                };
            startEndModel.startDateTime = this.getStartDate();
            startEndModel.endDateTime = this.getEndDate();
            current= startEndModel;
        }

        if (this.MetricsFilter !== null && current !== undefined) {
            this.MetricsFilter.StartDateTime = current.startDateTime;
            this.MetricsFilter.EndDateTime = current.endDateTime;
        }

        //@ts-ignore
        var next = jslinq(results)
            .where(function (item: any) {
                var thisShift = new Date(current.endDateTime);
                var startDateTime = new Date(item.startDateTime);
                var endDateTime = new Date(item.endDateTime);

                if ((startDateTime.getTime() >= thisShift.getTime()) && (endDateTime.getTime() >= thisShift.getTime())) {
                    return item;
                }
                return null;
            }).toList();

        //@ts-ignore
        minShift = jslinq(next)
            .min(function (el: any) {
                return el.total;
            });

        //@ts-ignore
        next = jslinq(next)
            .firstOrDefault(function (item: any) {
                return item.total === minShift;
            });

        //@ts-ignore
        var previous = jslinq(results)
            .where(function (item: any) {
                var thisShiftTime = new Date(current.startDateTime);
                var startDateTime = new Date(item.startDateTime);
                var endDateTime = new Date(item.endDateTime);

                if ((startDateTime.getTime() <= thisShiftTime.getTime()) && (endDateTime.getTime() <= thisShiftTime.getTime())) {
                    return item;
                }
                return null;
            }).toList();

        //@ts-ignore
        minShift = jslinq(previous)
            .min(function (el: any) {
                return el.total;
            });

        //@ts-ignore
        previous = jslinq(previous)
            .firstOrDefault(function (item: any) {
                return item.total === minShift;
            });


        currentNextShift.push(current);
        currentNextShift.push(next);
        currentNextShift.push(previous);
        return currentNextShift;
    }
    setFilterBarData(){
        var sdt = this.startDateTimeFormated(this.MetricsFilter.StartDateTime);
        var edt = this.endDateTimeFormated(this.MetricsFilter.EndDateTime);

        var contractLaborCountTxt = this.contractLaborCount > 0 ? "1+": this.contractLaborCount;
        var calloutCountTxt = this.calloutCount > 0 ? "1+": this.calloutCount;
        
        this.emitter.emit('setFilterBarData', 
                            { StartDateTime: sdt, 
                               EndDateTime: edt,
                               ContractLaborEnabled: this.defaults.contractLaborEnabled,
                               ContractLaborRedirectURL: this.defaults.AppUrl + 'Requests/ContractLaborRequest.aspx',
                               ContractLaborCount: contractLaborCountTxt,
                               CalloutCount: calloutCountTxt })
    }
    async showContractLaborCalloutPopup(flags: any){
        this.emitter.emit('showLoader');
        var filterData = { ...this.MetricsFilter };
        filterData.Departments = this.defaults.dashboardDepartmentIds;
        await this.$store
            .dispatch("dashboard/getContractlaborCalloutDetails", filterData)
            .then(() => {
                this.contractLaborCalloutDetails = this.contractlaborCalloutData;
                this.contractLaborCalloutFlags = flags;
                this.isContractLaborCalloutVisible = true;
                this.emitter.emit('hideLoader');
            })
            .catch((err: any) => {
                if (err) {
                    console.error(err); // Handle errors any way you want
                }
            }); 
    }
    closeCrtLbrCalloutPopup(){
        this.isContractLaborCalloutVisible = false;
    }

    metricsDataAutoRefresh(){
        var that= this;
        this.dashboardInterval = setInterval(function () {
            if ((that.defaults.disableRefresh === false) && (that.defaults.appMode != AppMode.NoAccess)) {
                that.refreshDashboard();
            }
        }, that.defaults.refreshRate);
    }

    autoRefreshChanged(isEnableAutoRefresh: any){
        if(isEnableAutoRefresh){
            this.defaults.disableRefresh = false;
        }
        else{
            this.defaults.disableRefresh = true;
        }
    }
    showToasterNotificationDashboardMain(data: any){
        this.msgValue = data.message;
        this.classFlag = data.flag;
        this.isToasterVisible = true;
    }
    printAggregatedSkillDashboard()
    {
        var dashboardGridForPrint: any = document.createElement('div');
        dashboardGridForPrint.setAttribute('id', 'aggregatedSkillDashboradPrint');
        //@ts-ignore
        dashboardGridForPrint.innerHTML = document.getElementById('aggregatedSkillDashborad').innerHTML;
        var mainPage: any = document.getElementById('app');
        mainPage.classList.add('d-none');
        document.body.appendChild(dashboardGridForPrint);

        window.print();
        document.body.removeChild(dashboardGridForPrint);
        mainPage.classList.remove('d-none');

        return true;
    }

    exportAggregatedSkillDashboardToExcel(){
        var downloadLink;
        var dataType = 'application/vnd.ms-excel';
        var tableSelect: any = document.getElementById('dashboardTable');
        var tableHTML = tableSelect.outerHTML.replaceAll(" - ", " to ").replaceAll("Min-Max", "Min to Max");
        
        // Specify file name
        var filename: any = 'AggregatedSkillDashboard.xls';
        
        // Create download link element
        downloadLink = document.createElement("a");
        
        document.body.appendChild(downloadLink);
        
        var blob = new Blob(['\ufeff', tableHTML], {
                type: dataType
            });

        var blobUrl = URL.createObjectURL(blob);

        // Create a link to the file
        downloadLink.href = blobUrl;
        
        // Setting the file name
        downloadLink.download = filename;
        
        //triggering the function
        downloadLink.click();
    }
}

</script>
<style>
  body {
    position: absolute !important;
    overflow: auto !important;
  }

  .dashboardTable{
    table-layout: fixed;
    width: 100%;
  }
  .dashboardTable th, .dashboardTable td{
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    border: none;
    border-bottom: 1px solid #CED1D7;
    border-left: 1px solid #CED1D7;
  }

  .dashboardTable th{
    color: whitesmoke;
  }

  .dashboardTable > tbody > tr:nth-of-type(odd) > * {
    background-color: rgba(0, 0, 0, 0.05);
    color: #212529;
  }

  .dashboardTable td {
    border-left: 1px solid #CED1D7;
    border-bottom: 1px solid #CED1D7;
  }

  .dashboardTable th {
    border: none;
    border-left: 1px solid #CED1D7;
    border-bottom: 2px solid white;
  }

  .dashboardTable > tbody > tr:hover > * {
    background-color: rgba(0, 0, 0, 0.075);
    color: #212529;
  }

  .scrollableDiv{
    overflow: auto!important;
    max-height: calc(100vh - 250px);
  }

  .dashboardTable thead{
    position: sticky;
    top: 0;
    z-index: 99;
  }

  .scrollBar::-webkit-scrollbar {
      -webkit-appearance: none;
  }

  .scrollBar::-webkit-scrollbar:vertical {
      width: 11px;
  }

  .scrollBar::-webkit-scrollbar:horizontal {
      height: 11px;
  }

  .scrollBar::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid white; /* should match background, can't be transparent */
      background-color: rgba(0, 0, 0, .5);
  }

  .scrollBar::-webkit-scrollbar-track {
      background-color: #fff;
      border-radius: 8px;
  }

  .facilityCol{
        position: sticky;
        left: 0;
        z-index: 4;
    }

    .clusterCol{
        position: sticky;
        left: 140px;
        z-index: 4;
    }

    .deptNameCol{
        position: sticky;
        left: 280px;
        z-index: 1;
    }

    .deptCodeCol{
        position: sticky;
        left: 420px;
        z-index: 1;
    }

    .shiftCol{
        position: sticky;
        left: 520px;
        z-index: 1;
    }

   @media print{
        table.dashboardTable td {
            font-size: xx-small !important;
            border: 1px solid silver;
            padding: 0px !important;
        }
        table.dashboardTable th {
            font-size: xx-small !important;
            border: 1px solid silver;
            padding: 0px !important;
        }

        .dashboardTable{
            table-layout: auto;
        }

        .scrollableDiv {
            overflow: hidden;
            max-height: inherit;
        }

        .dashboardTable thead{
            position: inherit;
        }

        .facilityCol{
            position: inherit;
            left: inherit;
            z-index: 0;
        }
    
        .clusterCol{
            position: inherit;
            left: inherit;
            z-index: 0;
        }
    
        .deptNameCol{
            position: inherit;
            left: inherit;
            z-index: 0;
        }
    
        .deptCodeCol{
            position: inherit;
            left: inherit;
            z-index: 0;
        }
    
        .shiftCol{
            position: inherit;
            left: inherit;
            z-index: 0;
        }
    }

</style>
