<template>
    <div id='assignment-cancel-form' style="margin-left:10px; margin-right:10px;">
        <div class="row bottom_border">
            <div class="col-sm-3">
                <div class="form-group">
                    <label class="font-weight-bold cancel-header1">Select Cancellation Type</label>
                </div>
            </div>
            <div class="col reset-padding">
                <div class="form-group">
                    <div class="btn-cancellation-type" :class="[isFacilityCanceled ? 'selected' : '']" data-category="2" data-unscheduled-absence="0" @click="onCancellationType('FacilityCanceled',2)">
                        <div><font-awesome-icon :icon="['fas', 'hospital']" size="lg" class="mr-2" />Facility Canceled</div>
                        <span></span>
                    </div>
                </div>
            </div>
            <div class="col reset-padding">
                <div class="form-group">
                    <div class="btn-cancellation-type" :class="[isEmployeeCanceledNA ? 'selected' : '']" data-category="1" data-unscheduled-absence="0" @click="onCancellationType('EmployeeCanceledNA',1)">
                        <div><font-awesome-icon :icon="['fas', 'user']" size="lg" class="mr-2" />Employee Canceled</div>
                        <span>N/A</span>
                    </div>
                </div>
            </div>
            <div class="col reset-padding">
                <div class="form-group">
                    <div class="btn-cancellation-type" :class="[isEmployeeCanceledEMC ? 'selected' : '']" data-category="1" data-unscheduled-absence="1" @click="onCancellationType('EmployeeCanceledEMC',1)">
                        <div><font-awesome-icon :icon="['fas', 'user']" size="lg" class="mr-2" />Employee Canceled</div>
                        <span>U/A - Employee Made Contact</span>
                    </div>
                </div>
            </div>
            <div class="col reset-padding">
                <div class="form-group">
                    <div class="btn-cancellation-type" :class="[isEmployeeCanceledNSNC ? 'selected' : '']" data-category="1" data-unscheduled-absence="2" @click="onCancellationType('EmployeeCanceledNSNC',1)">
                        <div><font-awesome-icon :icon="['fas', 'user']" size="lg" class="mr-2" />Employee Canceled</div>
                        <span>U/A - No Show / No Call</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3">
                <div class="form-group">
                    <label class="font-weight-bold cancel-header1">Select Cancel Reason</label>
                </div>
            </div>
            <div id="divCancelReasons" class="col">
                <div class="btn-cancellation-reason" v-for="cancelRsn in deptCancelReasons" :key="cancelRsn"
                     :data-reason-id="cancelRsn.reasonId" @click="onCancellationReasonClick(cancelRsn.reasonId)"
                     :class="[cancelRsn.isSelected ? 'selected': '']">
                    <div>{{cancelRsn.reason}}</div>
                </div>
            </div>
        </div>
        <div class="row bottom_border">
            <div class="col-sm-3">
                <div class="form-group">
                </div>
            </div>
            <div class="col reset-padding">
                <div class="form-group">
                    <input type="text" id="txtCancelComment"  placeholder="Enter Comment" v-model="txtCancelComment" class="inputBorder text-comment" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3">
                <div class="form-group">
                    <label class="font-weight-bold cancel-header1">Optional - Replace with Non-Productive Shift</label>
                </div>
                <div class="form-group divPTOBalance" style="text-align: center;font-size: 14px;" v-if="showPTOBalance">
                    <h6 style="margin-bottom: 0;">PTO Balance</h6>
                    <h4 id="pto_balance" style="border-bottom: 1px solid grey;width: 40%;display: inline-block;color: gray;font-weight: 700;">{{parseFloat(PTOBalanceData.ptoBalance).toFixed(2)}}</h4>
                    <h6 style="margin-bottom: 0;">Pay Period Accural</h6>
                    <h4 id="pay_period_accured" style="border-bottom: 1px solid grey;width: 40%;display: inline-block;font-weight: 700;color: gray;">{{parseFloat(PTOBalanceData.payPeriodAccrual).toFixed(2)}}</h4>
                    <template v-if="PTOBalanceData.ptoBalanceAccrual != null && PTOBalanceData.ptoBalanceAccrual.length > 0">
                        <div class="row txtLeft mt-3">
                            <div class="col-sm-6" style="padding: 0;">Pay Period Start</div><div class="col-sm-6" style=""><span id="pay_period_start">{{payPeriodStart}}</span></div>
                            <div class="col-sm-6" style="padding: 0;">PTO Balance</div><div class="col-sm-6" style=""><span id="pto_balance_hours">{{parseFloat(PTOBalanceData.ptoBalanceAccrual[0].ptoBalance).toFixed(2)}}</span> hours</div>
                            <div class="col-sm-6" style="padding: 0px;">Less Approved PTO</div><div class="col-sm-6" style=""><span id="less_approved_pto">{{PTOBalanceData.ptoBalanceAccrual[0].lessFsApprovalPto}}</span> hours</div>
                            <div class="col-sm-6" style="padding: 0;">Estimated PTO Hrs</div><div class="col-sm-6" style=""><span id="estimated_pto">{{parseFloat(PTOBalanceData.ptoBalanceAccrual[0].estPtoHours).toFixed(2)}}</span> hours</div>
                        </div>
                    </template>
                </div>
            </div>
            <div class="col" id="divShifts">
                <template v-for="dept in deptShifts" :key="dept">
                    <template v-if="dept.productiveId === NON_PRODUCTIVE && !dept.disabled">
                        <div class="divShifts">
                            <div class="btn-replace-non-productive-shift" :data-KronosPayCode="dept.KronosPayCode" 
                                :data-shift-id="dept.deptShiftId" :data-shiftStartTime="dept.shiftStartTime"
                                @click="nonProductiveShiftClick(dept.deptShiftId)" :class="[dept.isSelected ? 'selected': '']" >
                                <div>{{dept.code}}</div>
                                <span>{{dept.deptShiftDescription}}</span>
                            </div>
                        </div>
                    </template>
                </template>
            </div>
        </div>
        <div class="row" style="align-items: center">
            <div class="col-sm-3">
                <div class="form-group">
                    <label class="font-weight-bold"></label>
                </div>
            </div>
            <div class="col reset-padding">
                <div class="form-group">
                    <label class="font-weight-bold">Start Time:</label>
                </div>
            </div>
            <div class="col-sm-2">
                <div class="form-group">
                    <vue-timepicker format="h:mm A" :minute-interval="30" 
                        v-model="txtStartTime" :key="counterTime" 
                        manual-input auto-scroll close-on-complete></vue-timepicker>
                    <!-- <input type="text" id="txtStartTime" v-model="txtStartTime" style="width:110px; height: 34px;" /> -->
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <label class="font-weight-bold">Duration: </label>
                </div>
            </div>
            <div class="col-sm-5 txtLeft">
                <div class="form-group">
                    <input type="text" id="txtDurationHours" v-model="txtDurationHours" placeholder="Hrs" style="width:50px" class="inputBorder mr-2" />
                    <input type="text" id="txtDurationMins" v-model="txtDurationMins" placeholder="Mins" style="width:50px" class="inputBorder"  />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3">
                <div class="form-group">
                    <label class="font-weight-bold"></label>
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <neu-button type="button" fill="raised" id="btnCancelAssignment"
                        style="width:200px;display:block; margin: 0 auto;"
                        @click="CancelAssignment" >
                        Proceed with Cancellation
                    </neu-button>
                </div>
            </div>
        </div>
        <div class="assignment-cancel"></div>
    </div>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import moment from "moment";
import { mapState } from "vuex";
import jslinq from "jslinq";
import VueTimepicker from 'vue3-timepicker/src/VueTimepicker.vue';
import { Profile } from '@/models/profile/index';
import { useAppInsights } from '../../../store/modules/AppInsights';

//imports models and enums

//Import Componenets
import {
    NeuButton
} from "@neutron/vue";

class Props {
    assignmentCancelInput!: any 
}

@Options({
  computed: {
    ...mapState('actionable', ['departmentShifts', 'deptCancelReasonsByCategoryId', 'PTOBalance']),
    ...mapState('profile', ['profileData', 'appInsightEventData']),
  },
  components: {
    NeuButton,
    VueTimepicker 
  }
}) 
export default class AssignmentCancel extends Vue.with(Props) {
    //Data properties
    departmentShifts!: any;
    deptCancelReasonsByCategoryId!: any;
    PTOBalance!: any;
    profileData!: Profile;
    deptShifts: any = null;
    deptCancelReasons: any = null;
    PTOBalanceData: any = null;
    NON_PRODUCTIVE: number = 3;
    counterTime: number = 0;

    //Cancellation Type
    isFacilityCanceled: boolean = false;
    isEmployeeCanceledNA: boolean = false;
    isEmployeeCanceledEMC: boolean = false;
    isEmployeeCanceledNSNC: boolean = false;
    appInsightEventData!: any;
    payPeriodStart: any = null;
    showPTOBalance: boolean = false;
    txtCancelComment: string = ""; 
    txtStartTime: any = {
        "h": "", 
        "mm": "",
        "A": ""
    }; 
    txtDurationHours: string = "";  
    txtDurationMins: string = ""; 

    //Lifecycle hooks
    async mounted(){
        this.loadAssignmentCancel();
    }

    //Methods
    loadAssignmentCancel(){
        this.onCancellationType('FacilityCanceled',2);
        this.bindNonProductiveShifts();
        this.loadPTOBalance();
    }

    async bindNonProductiveShifts(){
        var inputData = {
            departmentId: this.assignmentCancelInput.departmentId,
            date: moment(this.assignmentCancelInput.date).format('YYYY-MM-DD')
        }
         await this.$store
                .dispatch("actionable/getDepartmentShifts", inputData)
                .then(() => {
                    if (this.departmentShifts != null && this.departmentShifts.length > 0) {
                        this.deptShifts = this.departmentShifts;
                        for (var i = 0; i < this.deptShifts.length; i++) {
                            this.deptShifts[i] = Object.assign(this.deptShifts[i], {
                                shiftStartTime: moment(this.deptShifts[i].startTime).format('h:mm A'),
                                isSelected: false
                            });
                        }
                    }
                })
                .catch((err: any) => {
                    if (err) {
                        var errorData = {
                            message: "An error occured while retrieving Department Shift.",
                            flag: "error"
                        }
                        this.emitter.emit('showToasterNotificationAssignment', errorData);
                        console.error(err); // Handle errors any way you want
                    }
                });
    }

    async onCancellationType(cancellationType: string, categoryId: number){
        this.isFacilityCanceled = false;
        this.isEmployeeCanceledNA = false;
        this.isEmployeeCanceledEMC = false;
        this.isEmployeeCanceledNSNC = false;

        switch (cancellationType) {
            case 'FacilityCanceled':
                this.isFacilityCanceled = true;
                break;
            case 'EmployeeCanceledNA':
                this.isEmployeeCanceledNA = true;
                break;
            case 'EmployeeCanceledEMC':
                this.isEmployeeCanceledEMC = true;
                break;
            case 'EmployeeCanceledNSNC':
                this.isEmployeeCanceledNSNC = true;
                break;
        }

        var inputData = {
            departmentId: this.assignmentCancelInput.departmentId,
            categoryId: categoryId,
            tenantId: this.profileData.TenantId
        }
        await this.$store
                .dispatch("actionable/getDepartmentCancelReasonsByCategoryId", inputData)
                .then(() => {
                    if (this.deptCancelReasonsByCategoryId != null && this.deptCancelReasonsByCategoryId.length > 0) {
                        this.deptCancelReasons = this.deptCancelReasonsByCategoryId;

                        for (var i = 0; i < this.deptCancelReasons.length; i++) {
                            this.deptCancelReasons[i] = Object.assign(this.deptCancelReasons[i], {
                                isSelected: false
                            });
                        }
                    }
                })
                .catch((err: any) => {
                    if (err) {
                        var errorData = {
                            message: "An error occured while retrieving Department Cancel Reasons.",
                            flag: "error"
                        }
                        this.emitter.emit('showToasterNotificationAssignment', errorData);
                        console.error(err); // Handle errors any way you want
                    }
                });
    }

    onCancellationReasonClick(reasonId: any){
        this.deptCancelReasons.forEach((dept: any) => {
                    if(dept.reasonId == reasonId){
                        dept.isSelected = true;
                    }
                    else{
                        dept.isSelected = false;
                    }
                });
    }

    async loadPTOBalance(){
        var inputData = {
            staffId: this.assignmentCancelInput.staffId,
            startDate: this.assignmentCancelInput.date,
        }
        await this.$store
                .dispatch("actionable/getPTOBalance", inputData)
                .then(() => {
                    if (this.PTOBalance != null) {
                        this.PTOBalanceData = this.PTOBalance;
                        if(this.PTOBalanceData.ptoBalanceAccrual.length > 0){
                            this.payPeriodStart = moment(this.PTOBalanceData.ptoBalanceAccrual[0].payPeriodEndDate).format('M/D/YY');
                        }
                        this.showPTOBalance = false;
                    }
                })
                .catch((err: any) => {
                    if (err) {
                        var errorData = {
                            message: "An error occured while retrieving PTO Balance.",
                            flag: "error"
                        }
                        this.emitter.emit('showToasterNotificationAssignment', errorData);
                        console.error(err); // Handle errors any way you want
                    }
                });
    }

    nonProductiveShiftClick(deptShiftId: number){
        //@ts-ignore
        var selectedDeptShift: any = jslinq(this.deptShifts)
                                .where((data: any) => { 
                                    if(data.deptShiftId == deptShiftId){
                                        return data;
                                    }})
                                .toList();
        
        if(selectedDeptShift != null && selectedDeptShift.length > 0){
            if(selectedDeptShift[0].isSelected){
                this.deptShifts.forEach((shift: any) => {
                    if(shift.deptShiftId == deptShiftId){
                        shift.isSelected = false;
                    }
                });
                this.counterTime = this.counterTime + 1;
                this.txtStartTime.h = "";
                this.txtStartTime.mm = "";
                this.txtStartTime.A = "";
                this.showPTOBalance = false;
            }
            else{
                this.deptShifts.forEach((shift: any) => {
                    if(shift.deptShiftId == deptShiftId){
                        shift.isSelected = true;
                        var hours: number = moment(shift.shiftStartTime, 'h:mm A').hours();
                        var minutes: number = moment(shift.shiftStartTime, 'h:mm A').minutes();
                        var timeTxt = shift.shiftStartTime.split(" ");
                        this.counterTime = this.counterTime + 1;
                        this.txtStartTime.h = hours.toString();
                        this.txtStartTime.mm = minutes == 0 ? "00" : minutes.toString();
                        this.txtStartTime.A = timeTxt[1];
                        
                        if (this.getPayCodesForPTO().indexOf(shift.code) >= 0) {
                            this.showPTOBalance = true;
                        }
                        else {
                            this.showPTOBalance = false;
                        }
                    }
                    else{
                        shift.isSelected = false;
                    }
                });
            }
        }
    }

    getPayCodesForPTO() {
        return ["LVPTO", "PTO", "NBLVP", "NBPTO", "SOU", "PTOK", "PTO-S", "UNPTO"];
    }

    CancelAssignment(){
        var assignmentCancelModel: any = {};
        assignmentCancelModel.AssignId = this.assignmentCancelInput.assignId;

        if(!this.isFacilityCanceled && !this.isEmployeeCanceledNA && !this.isEmployeeCanceledEMC && !this.isEmployeeCanceledNSNC){
            this.sendToasterMessage('Please select Cancellation Type.', "error");
            return;
        }
        else{
            assignmentCancelModel.UnscheduledAbsenceOptionId = this.getUnscheduledAbsence();
            assignmentCancelModel.UnscheduledAbsence = (assignmentCancelModel.UnscheduledAbsenceOptionId != 0);
        }

        //@ts-ignore
        var selectedCancelReasons: any = jslinq(this.deptCancelReasons)
                                .where((data: any) => { 
                                    if(data.isSelected){
                                        return data;
                                    }})
                                .toList();

        if(selectedCancelReasons != null && selectedCancelReasons.length > 0){
            assignmentCancelModel.CancelReasonId = selectedCancelReasons[0].reasonId
        }
        else{
            this.sendToasterMessage('Please select Cancel Reason.', "error");
            return;
        }

        if (assignmentCancelModel.CancelReasonId == 0 && this.txtCancelComment.trim() =='') {
            this.sendToasterMessage('Please enter Comment for Cancellation.', "error");
            return;
        } else {
            assignmentCancelModel.Note = this.txtCancelComment;
        }

        var replacementAssignment: any = {};
        //@ts-ignore
        var selectedDeptShift: any = jslinq(this.deptShifts)
                                .where((data: any) => { 
                                    if(data.isSelected){
                                        return data;
                                    }})
                                .toList();
        if (selectedDeptShift != null && selectedDeptShift.length > 0) {
            replacementAssignment = JSON.parse(JSON.stringify(this.assignmentCancelInput));
            replacementAssignment.departmentShiftId = selectedDeptShift[0].deptShiftId;
            replacementAssignment.floatChangedByUser = 1;
            replacementAssignment.skillLevelID = replacementAssignment.skillLevelId;
            var startTime = this.txtStartTime.h + ':'+ this.txtStartTime.mm + ' ' + this.txtStartTime.A;
            if (!moment(moment(startTime, "H:m A", true)).isValid()) {
                this.sendToasterMessage('Please enter valid Start Time.', "error");
                return;
            } else {
                replacementAssignment.startTime = moment("01-01-1900 " + startTime, "DD-MM-YYYY H:m A", true).toDate();
            }
            if (this.txtDurationHours.trim() == '' || isNaN(Number(this.txtDurationHours.trim()))) {
                this.sendToasterMessage('Please enter valid Hours.', "error");
                return;
            } else {
                replacementAssignment.durationHours = this.txtDurationHours.trim();
            }

            if (isNaN(Number(this.txtDurationMins.trim()))) {
                this.sendToasterMessage('Please enter valid Minutes.', "error");
                return;
            } else if (this.txtDurationMins.trim() == '') {
                replacementAssignment.durationMinutes = 0;
            } else {
                replacementAssignment.durationMinutes = this.txtDurationMins.trim();
            }
            if (this.txtDurationHours.trim() == '0' && this.txtDurationMins.trim() == '0') {
                this.sendToasterMessage('Shift duration cannot be zero.', "error");
                return;
            }
            replacementAssignment.endTime = moment(replacementAssignment.startTime).add(replacementAssignment.durationHours, 'hours').add(replacementAssignment.durationMinutes, 'minutes').toDate();

        }
        else{
            replacementAssignment = null;
        }

        useAppInsights().trackEvent({
            name: "DASHBOARD_SMS_ASSIGNMENT_CANCELLATION", properties:
                JSON.parse(JSON.stringify(this.appInsightEventData))
        });

        this.cancelAndReplcaseAssignment(assignmentCancelModel, replacementAssignment);
    }

    sendToasterMessage(msg: string, flag: string){
        var errorData = {
            message: msg,
            flag: flag
        }
        this.emitter.emit('showToasterNotificationAssignment', errorData);
    }

    getUnscheduledAbsence(){
        var UnscheduledAbsenceOptionId: number = 0;
        if(this.isFacilityCanceled){
            UnscheduledAbsenceOptionId = 0;
        } 
        else if(this.isEmployeeCanceledNA){
            UnscheduledAbsenceOptionId = 0; 
        }
        else if(this.isEmployeeCanceledEMC){
            UnscheduledAbsenceOptionId = 1;
        }
        else if(this.isEmployeeCanceledNSNC){
            UnscheduledAbsenceOptionId = 2;
        }

        return UnscheduledAbsenceOptionId;
    }

    async cancelAndReplcaseAssignment(assignmentCancelModel: any, replacementAssignment: any){
        await this.$store
                .dispatch("assignments/cancelAssignment", assignmentCancelModel)
                .then(() => {
                    if (replacementAssignment != null) {
                        this.createAssignment(replacementAssignment);
                    }
                    else{
                        this.sendToasterMessage('Assignment cancelled successfully', "success");
                        this.emitter.emit('unloadAssignment');
                    }
                })
                .catch((err: any) => {
                    if (err) {
                        var response = JSON.parse(err.request.response);
                        var errorData = {
                            msg: response.Message,
                            flag: "error"
                        }
                        this.emitter.emit('showAlertPopup', errorData);
                        console.error(err); // Handle errors any way you want
                    }
                });
    }

    async createAssignment(replacementAssignment: any){
        const assignmentData = {
            AssignId: replacementAssignment.assignId,
            DepartmentId: replacementAssignment.departmentId,
            SkillLevelID: replacementAssignment.skillLevelID,
            Date: replacementAssignment.date,
            DepartmentShiftId: replacementAssignment.departmentShiftId,
            Charge: replacementAssignment.charge,
            IsFloat: replacementAssignment.isFloat,
            Note: replacementAssignment.note,
            StartTime: replacementAssignment.startTime,
            EndTime: replacementAssignment.endTime,
            DurationHours: replacementAssignment.durationHours,
            DurationMinutes: replacementAssignment.durationMinutes,
            StaffId: replacementAssignment.staffId,
            floatChangedByUser: replacementAssignment.floatChangedByUser,
            AssociationType: replacementAssignment.associationType,
            ProductiveId: replacementAssignment.productiveId
        };

        await this.$store
                .dispatch("assignments/createAssignment", assignmentData)
                .then(() => {
                    this.sendToasterMessage('Assignment cancelled successfully', "success");
                    this.emitter.emit('unloadAssignment');
                })
                .catch((err: any) => {
                    if (err) {
                        var response = JSON.parse(err.request.response);
                        var errorData = {
                            msg: response.Message,
                            flag: "error"
                        }
                        this.emitter.emit('showAlertPopup', errorData);
                        console.error(err); // Handle errors any way you want
                    }
                });
    }

}
</script>
<style scoped>
.invalid-msg {
    border-color: #ffb400;
    background-color: #ffb400;
    color: #000;
}
 
.skill-description {
    width: Auto;
    float: left;    
}

.skill-color {
   color:red;
}
.skill-note {    
    float: right;
    font-size: 11px;
    text-align:right;    
}
.cancel-header1 {
    font-size: 20px !important;
    font-weight: 500 !important;
}
.cancel-header2 {
    font-size: 15px !important;
}
.btn-cancellation-type {
    border: 2px solid #2d719f;
    min-height: 60px;
    border-radius: 6px;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}
    .btn-cancellation-type i {
        left: 8%;
        display: block;
        position: absolute;
        color: #2d719f;
    }
    .btn-cancellation-type div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        padding-top: 5px;
        position : relative;
    }
    .btn-cancellation-type span {
        font-size: 12px;
        align-items: center;
        justify-content: center;
        width: 100%;
        display: flex;
        padding-top: 5px;
    }
    .btn-cancellation-type.selected {
        background-color: #e6f2fa;
    }
    .btn-cancellation-type:hover {
        background-color: #e6f2fa;
    }
.btn-cancellation-reason {
    width: 20%;
    margin-bottom: 10px;
    padding-left: 7px;
    padding-right: 7px;
    float: left;
}
    .btn-cancellation-reason div {
        border: 2px solid #2d719f;
        min-height: 60px;
        border-radius: 6px;
        vertical-align: middle;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
    }
    .btn-cancellation-reason.selected div {
        background-color: #e6f2fa;
    }
    .btn-cancellation-reason:hover div {
        background-color: #e6f2fa;
    }
.divShifts {
    float: left;
    padding-left: 7px;
    padding-right: 7px;
    margin-bottom: 10px;
    width: 14%;
}
.btn-replace-non-productive-shift {
    width: 100%;
    border: 2px solid #2d719f;
    min-height: 60px;
    border-radius: 6px;
    text-align: center;
    vertical-align: middle;
    padding-top: 5px;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}
    .btn-replace-non-productive-shift div {
        font-size: 18px;
        font-weight: 600;
    }
    .btn-replace-non-productive-shift span {
        font-size: 12px;
        line-height: 1;
        display: inline-block;
        margin-left: 2px;
        margin-right: 2px;
    }
    .btn-replace-non-productive-shift.selected {
        background-color: #e6f2fa;
    }
    .btn-replace-non-productive-shift:hover {
        background-color: #e6f2fa;
    }
.reset-padding {
    padding-left: 7px !important;
    padding-right: 7px !important;
}
.text-comment {
    width: 100%;
    height: 34px;
    border-radius: 5px;
    border: 1px solid grey;
}
.bottom_border {
    border-bottom: 1px solid rgba(173,180,189, 0.3);
    margin-bottom: 15px;
}
#divCancelReasons {
    padding-left: 0;
    padding-right: 0;
}
#divShifts {
    padding-left: 0;
    padding-right: 0;
}
.btn.disabled, .btn:disabled {
    cursor: not-allowed;
}

.inputBorder{
    border: 1px solid #c9c9c9 !important;
}
</style>
