<template>
    <div v-for="(data, index) in dailyRosterCompressedReportInput" :key="data">
        <div class="dailyRosterDetail-card p-2 mb-2" style="background-color: white" :data-departmentid="data.departmentId" :data-businessday="GetBusinessDay(data.businessDay)">        
            <div :class="['heading ', index != 0 ? 'dailyRoasterPrintPageBreak': '']"> 
                <div class="row">
                    <div class="col-md-5">
                        <h5>{{'(' + data.facilityCoId + ') ' + data.facilityName}}</h5> 
                    </div>
                    <div class="col-md-2 buttonGroup" v-if="index == 0 && data.summaries != null && data.summaries.length > 0">
                        <button title="Previous Date" id="btnPreviousDate" @click="onDayClick(false)">{{'<'}}</button>
                        <button title="Selected Date" id="btnSelectedDate" style="">{{(data.summaries != null && data.summaries.length > 0) ? GetSummarySectionDate(data.summaries[0].startDateTime) : ''}}</button>
                        <button title ="Next Date" id="btnNextDate" @click="onDayClick(true)">{{'>'}}</button>
                    </div>
                    <div class="col">
                        <h5 class="float-end">{{ '(' + data.departmentCode + ') ' + data.departmentName}}</h5> 
                    </div> 
                </div>
            </div> 
            <hr> 
            <template v-if="data.summaries != null && data.summaries.length > 0">
                <div class="dailyroster-skills-census-summaries"> 
                    <div class="table-responsive">
                        <h5>Compressed View</h5>
                        <table class="table table-bordered dailyRostersummaries-skill-grid daily-roster-compressed-print">
                            <thead></thead>
                            <tbody>
                                <tr class="dr-date-row dr-heading">
                                    <td class="leading-cell">Date</td>
                                    <template v-for="dataItemDate in data.summaries" :key="dataItemDate">
                                        <td class="NumColum" colspan="3">{{GetSummarySectionDate(dataItemDate.startDateTime)}}</td>
                                    </template>
                                </tr>
                                <tr class="dr-start-time-row dr-heading"> 
                                    <td class="leading-cell"> Start Time</td> 
                                    <template v-for="dataItemSTime in data.summaries" :key="dataItemSTime">
                                        <td class="NumColum" colspan="3">{{AssignmentStartTime(dataItemSTime.startDateTime)}}</td>
                                    </template>
                                </tr>
                                <tr class="dr-end-time-row dr-heading"> 
                                    <td class="leading-cell"> End Time</td> 
                                    <template v-for="dataItemETime in data.summaries" :key="dataItemETime">
                                        <td class="NumColum" colspan="3">{{AssignmentEndTime(dataItemETime.endDateTime)}}</td>
                                    </template>
                                </tr>
                                <tr class="dr-staffto-row dr-heading"> 
                                    <td class="leading-cell"> StaffTo</td> 
                                    <template v-for="dataItemStaffTo in data.summaries" :key="dataItemStaffTo">
                                        <td class="NumColum" colspan="3">{{Number(dataItemStaffTo.staffTo) ? dataItemStaffTo.staffTo : '-'}}</td>
                                    </template>
                                </tr>
                                <tr class="dr-actualcensus-row dr-heading"> 
                                    <td class="leading-cell"> Actual Census</td> 
                                    <template v-for="dataItemActualCensus in data.summaries" :key="dataItemActualCensus">
                                        <td class="NumColum" colspan="3">{{Number(dataItemActualCensus.actualCensus) ? dataItemActualCensus.actualCensus : '-'}}</td>
                                    </template>
                                </tr>
                                <tr class="dr-forecast-census dr-heading"> 
                                    <td class="leading-cell"> Forecast Cesus</td> 
                                    <template v-for="dataItemForecastCesus in data.summaries" :key="dataItemForecastCesus">
                                        <td class="NumColum" colspan="3">{{Number(dataItemForecastCesus.forecastCensus) ? dataItemForecastCesus.forecastCensus : '-'}}</td>
                                    </template>
                                </tr>
                                <tr class="dr-skills-heading dr-heading"> 
                                    <td class="leading-cell"> Skills</td> 
                                    <template v-for="dataItemSkill in data.summaries" :key="dataItemSkill">
                                        <td class="NumColum" :data-departmentsummaryid="dataItemSkill.departmentSummaryId" data-skillsummarytype="scheduled">S</td>
                                        <td class="NumColum" :data-departmentsummaryid="dataItemSkill.departmentSummaryId" data-skillsummarytype="target">T</td>
                                        <td class="NumColum" :data-departmentsummaryid="dataItemSkill.departmentSummaryId" data-skillsummarytype="difference">D</td>
                                    </template>
                                </tr>
                                <tr class="skill-row" v-for="(dataItemSkillLevel, arrIndex) in GetDailyRostersummariesSkills(data.summaries)" :key="dataItemSkillLevel">
                                    <template v-for="(dataItemSkillLevelDetails, index) in dataItemSkillLevel" :key="dataItemSkillLevelDetails">
                                        <template v-if="((index % 3 == 0) && index != 0)">
                                            <td :class="['NumColum', arrIndex == GetDailyRostersummariesSkills(data.summaries).length - 1 ? 'font-weight-bold' : '' ,
                                                     dataItemSkillLevelDetails < 0 ? 'gridUnderStaff' : dataItemSkillLevelDetails > 0 ? 'gridOverStaff' : '']">
                                                {{dataItemSkillLevelDetails}}
                                            </td>
                                        </template>
                                        <template v-else>
                                            <td :class="[index != 0 ? 'NumColum' : '',
                                                    arrIndex == GetDailyRostersummariesSkills(data.summaries).length - 1 ? 'font-weight-bold' : '']">
                                                {{dataItemSkillLevelDetails}}
                                            </td>
                                        </template>
                                    </template>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </template>
            <div class="dailyroster-skills-census-summaries" v-if="data.assignments != null && data.assignments.length > 0">
                <div class="row" >
                    <div class="col">
                        <div class="dailyRoster-assignments-grid">
                            <div color="plain-0" class="p-0 m-0">
                                <table class="neutable daily-roster-compressed-print">
                                    <colgroup>
                                        <template v-for="column in Object.keys(columnHeaders)" :key="column">
                                            <col :style="{'width': columnHeaders[column] }" >
                                        </template>
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <th v-for="column in Object.keys(columnHeaders)" :key="column">
                                                {{ column }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody align="left">
                                        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                        <tr  v-for="dataItem in data.assignments" :slot="column"
                                                    :key="dataItem" :class="getAssignmentClassForRow(dataItem)" @click="onEditingShift(dataItem)" class="pointer">
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <td  :title="dataItem.departmentCode" class="department-col">
                                                <span v-if="dataItem.origDeptId !== dataItem.departmentId">
                                                {{ dataItem.departmentCode }} <br /><span style="text-decoration: line-through">{{dataItem.origDepartmentCode}}  </span>
                                              </span>
                                              <span v-else> {{ dataItem.departmentCode }}</span>
                                            </td>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <td :title="dataItem.staffLastName + ' ' + dataItem.staffFirstName" class="employee-col">
                                                <span :class="[(dataItem.origDeptId !== dataItem.departmentId && dataItem.origDeptId === data.departmentId)? 'strikethrough': '']"> 
                                                {{ dataItem.staffLastName + ' ' + dataItem.staffFirstName }} </span>
                                                <span v-if="dataItem.charge">
                                                    <font-awesome-icon :icon="['fa', 'user-md']" size="sm" class="chargeIcon" />
                                                </span>
                                                <span v-if="dataItem.associationType === defaults.secondaryStaff" >                                                                                        
                                                    <img src="@/assets/sIcon.png" class="associaionIcon" title="Secondary Association">
                                                </span> 
                                                <span v-if="dataItem.associationType === defaults.unassociatedStaff">
                                                    <img src="@/assets/uIcon.png" class="associaionIcon" title="Unassociated">
                                                </span>
                                                <span v-if="dataItem.float">
                                                    <img class="associaionIcon" src="@/assets/float.png" alt="Float" />
                                                </span>     
                                            </td>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <td  :title="dataItem.credentialsExpiration">
                                                {{ dataItem.credentialsExpiration }}
                                            </td>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <td :title="dataItem.skillLevelDescription">
                                                <span :class="[(dataItem.origDeptId !== dataItem.departmentId && dataItem.origDeptId === data.departmentId)? 'strikethrough': '']"> 
                                                    {{ dataItem.skillLevelDescription }} </span>
                                            </td>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <td :title="dataItem.hours" 
                                                :class="[(dataItem.isOvertime && !dataItem.cancel) ? 'hours-col overtime': 'hours-col']">
                                                {{ dataItem.hours }}
                                            </td>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <td  :title="dataItem.employeeType">
                                                {{ dataItem.employeeType }}
                                            </td>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <td  :title="dataItem.fte">  
                                                {{ dataItem.fte }}
                                            </td>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <td  :title="dataItem.shiftCode" @click="onEditingShift(dataItem)"
                                                :class="[(dataItem.overtimeShift && !dataItem.cancel) ? 'shift-col overtime': 'shift-col']">
                                                {{ dataItem.shiftCode }}
                                            </td>
                                            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                                            <td class="notes-col">
                                                <span v-if="dataItem.hasNotes">
                                                    <span class="tooltip" @mouseover="showToolTip($event)"><font-awesome-icon :icon="['fa', 'sticky-note']" size="sm"
                                                        @click="onEditingShift(dataItem)" />
                                            <span class="tooltiptext">Notes: <br> {{ setGridDateFormat(dataItem.notedate) }} <br> {{  dataItem.note.length > 50 ? dataItem.note.replace(' ','').substring(0,50) + "..." : dataItem.note }}
                                            </span>
                                        </span>                                                      
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <template v-if="data.plusMetrics != null">
                <PlusMetricsReport :plusMetricsReportInput="data.plusMetrics"
                                    :facilityName="data.facilityName"
                                    :departmentName="data.departmentName"/>
            </template>
            <template v-if="data.clockIns != null && data.clockIns.shiftClockIns != null && data.clockIns.shiftClockIns.length > 0">
                <ClockInsReport :clockInsInput="data.clockIns" />
            </template>
        </div>
    </div>
    <template v-if="isEditAssignment">
        <EditAssignment :assignmentInput="assignmentInput" :assignmentType="assignmentType" 
                @close-modal="closeEditAssignment"/>
    </template>
</template>
<script lang="ts">
//import plugins and modules
import { Options, Vue } from 'vue-class-component';
import moment from "moment";
import { mapState } from "vuex";
import jslinq from "jslinq";

//import model and enum

//Import Componenets
import PlusMetricsReport from './PlusMetricsReport.vue';
import ClockInsReport from './ClockInsReport.vue';
import EditAssignment from './EditAssignment.vue';

class Props {
    dailyRosterCompressedReportInput!: any 
}
@Options({
    components: {
        PlusMetricsReport,
        ClockInsReport,
        EditAssignment
    }
})
export default class DailyRosterCompressedReport extends Vue.with(Props) {  
    //Data properties
    defaults: any= {
        config: {},
        numberOfsummariesToDisplayPerRow: 6,
        otherProductiveAssignment: 2,
        nonProductiveAssignment: 3,
        callAssignment:4,
        unassociatedStaff: 3,
        secondaryStaff: 2,
        timeOnlyFormat: 'hh:mm A'
    };
    columnHeaders: any = {
        Dept: "6%",
        Employee: "20%",
        Credentials: "20%",
        Skills: "13%",
        Hours: "6%",
        Type: "10%",
        FTE: "7%",
        Shift: "9%",
        Notes: "9%"
    };
    columnIcons: any = {
        Department: "none",
        Employee: "none",
        Credentials: "none",
        Skills: "none",
        Hours: "none",
        Type: "none",
        FTE: "none",
        Shift: "",
        Notes: ""
    };
    timeOnlyFormat: string = 'hh:mm A';
    numberOfSummariesToDisplayPerRow: number = 6;
    viewModel: any = {
        reportData: [],
        lastEditedAssignmentId:null
    };
    isEditAssignment: boolean = false;
    assignmentInput: any = '';
    assignmentType: any = 'View';

    //Lifecycle hooks
    mounted(){
        this.dailyRosterCompressedReportInput.forEach((data: any) => {              
            this.viewModel.reportData.push(data);            
        });
    }

    created(){
        this.emitter.on("unloadAssignment", this.unloadAssignment);
    }

    unmounted(){
        this.emitter.off("unloadAssignment", this.unloadAssignment);
    }

    //Methods  
    showToolTip(event: any){
            // eslint-disable-next-line 
            const tooltip : NodeListOf<HTMLSpanElement> = document.querySelectorAll(".tooltiptext");
            const mouseX = event.clientX;
            const mouseY = event.clientY;
            console.log(tooltip)
            if(tooltip != null){
                for(let i = 0; i < tooltip.length; i++){
                    tooltip[i].style.left = mouseX - 410 + 'px';
                    tooltip[i].style.top = mouseY - 40 + 'px';
                }
            }            
        }

    setGridDateFormat(date: any) {
            if (date != null && date != '') {
                var formattedDate: any = moment(date).format("MM/DD/YYYY");
                return formattedDate;
            }
            else {
                return '';
            }
        }

    GetBusinessDay(businessDay: any){
       return moment(businessDay).format('MM-DD-YYYY');
    }

    GetSummarySectionDate(businessDay: any){
        return moment(businessDay).format('MM/DD/YYYY');
    }

    GetSkillText(skills: any){
        var skillsText = '';
        if (skills != null && skills.length > 0) {
            skills.forEach((skill: any) => {
                skillsText += skill.skillLevelDesc + ' : ' + skill.count + ' | ';
            });
        }
        return skillsText.slice(0, -3);
    }

    GetTotalSchedule(skills: any){
        var totalScheduled = 0;
        if (skills != null && skills.length > 0) {
            skills.forEach((skill: any) => {
                totalScheduled += Number(skill.count);
            });
        }
        return totalScheduled;
    }

    AssignmentStartTime(startTime: any){ 
        return moment(startTime).format(this.timeOnlyFormat);
    }

    AssignmentEndTime(endTime: any){
        return moment(endTime).format(this.timeOnlyFormat);
    }

    GetCompetencies(assignment: any){
        var competencies=[];

        if (!assignment) {
            return '';
        }

        competencies = assignment.competencies.map(function (comp: any) {
            return comp.code;
        });

        return competencies.toString(); 
    }

    GetDailyRostersummaries(summaries: any){
        var splitsummaries = this.splitIntoChunks(summaries, this.defaults.numberOfsummariesToDisplayPerRow);
        return splitsummaries;
    }

    GetDailyRostersummariesSkills(summaries: any){
        var skillList: any[] = [];

        if(summaries != null && summaries.length > 0){
            var skills = summaries[0].skillLevels;

            for(let i=0; i<skills.length; i++){
                var skillRow: any[] = [];
                var isMetricsAvailable: boolean = false;

                //@ts-ignore
                isMetricsAvailable = jslinq(summaries)
                    .any(function (item: any) {
                        return item.skillLevels[i].scheduled != 0 || item.skillLevels[i].target != 0;
                    });

                if(isMetricsAvailable){
                    skillRow.push(skills[i].skillLevelDesc);
                    summaries.forEach((item: any) => {
                        skillRow.push(item.skillLevels[i].scheduled)
                        skillRow.push(item.skillLevels[i].target)
                        skillRow.push(item.skillLevels[i].difference)
                    });
                    skillList.push(skillRow);
                }
            }
        }
        return skillList;
    }

    GetDailyRostersummariesSkillsTotal(summaries: any){
        var skillList: any[] = [];
        
        skillList.push('Total');
        if(summaries != null && summaries.length > 0){
            summaries.forEach((item: any) => {
                var scheduledTotal = 0,
                    targetTotal = 0,
                    differenceTotal = 0;
                if(item.skillLevels != null &&  item.skillLevels.length > 0){
                    item.skillLevels.forEach((skill: any) => {
                        scheduledTotal += skill.scheduled;
                        targetTotal += skill.target;
                        differenceTotal += skill.difference;
                    });
                }
                skillList.push(scheduledTotal.toFixed(2));
                skillList.push(targetTotal.toFixed(2));
                skillList.push(differenceTotal.toFixed(2)); 
            });
        }
        return skillList;
    }

    splitIntoChunks(array: any, limit: any) {
        var result = [],
            copyArray = array.slice(),
            itemsInArray = true;

        while (itemsInArray) {
            result.push(copyArray.splice(0, limit));
            itemsInArray = copyArray.length;
        }

        return result;
    }
    
    getAssignmentClassForRow(assignment: any) {
        var className = '';

        if (!assignment) {
            return className;
        }

        className = assignment.cancel ? " cancelledAssignment" : "";
        className += assignment.profileCharge && assignment.charge ? " chargeAssignment" : "";
        className += assignment.float ? " floatedAssignment" : "";
        className += assignment.cancel ? " strikethrough" : "";

        switch (+assignment.productiveId) {
            case this.defaults.nonProductiveAssignment:
                className += " non-productive";
                break;
            case this.defaults.otherProductiveAssignment:
                className += " other-productive";
                break;
            case this.defaults.callAssignment:
                className += " call-assignment";
                break;
            default:
                break;
        }
        return className.trim();
    }

    closeEditAssignment(){
        this.unloadAssignment();
    }
    
    unloadAssignment() {
        this.isEditAssignment = false;
        this.emitter.emit('refreshDailyRoster');
    }

    onDayClick(isAddDate: any){
        this.emitter.emit('onDayClick', isAddDate);
    }

    onEditingShift(dataItem: any) {
        var assigmentId = dataItem.assignmentId;
        var departmentId = dataItem.departmentId;

        var assigmentDetails = this.findAssignmentInDepartment(departmentId, assigmentId);
        var assigmentData = this.mapAssignmentToBeUpdated(assigmentDetails);

        this.viewModel.lastEditedAssignmentId = assigmentId;             
        this.assignmentInput = assigmentData;
        this.isEditAssignment = true;
    }

    findAssignmentInDepartment (departmentId: any, assignmentId: any) {
        var reportData = this.viewModel.reportData, i;

        if (reportData ){
            for (i = 0; i < reportData.length; i++) {
                if (reportData[i].departmentId === departmentId) {
                    var assignmentInfo = this.findAssignment(reportData[i].assignments, assignmentId);
                    if(assignmentInfo != undefined)
                    {
                        assignmentInfo.facilityId = reportData[i].facilityId;
                        assignmentInfo.facilityName = reportData[i].facilityName;
                        assignmentInfo.clusterId = reportData[i].clusterId;
                        assignmentInfo.clusterName = reportData[i].clusterName;
                        assignmentInfo.facilityPriorDatesEditable = reportData[i].facilityPriorDatesEditable;
                        return assignmentInfo;
                    }
                }
            }
        }
    }

    findAssignment (assignmentList: any, assignmentId: any) {
        var i;
        if (assignmentList) {
            for (i = 0; i < assignmentList.length; i++) {
                if (assignmentList[i].assignmentId === assignmentId) {
                    return assignmentList[i];
                }
            }
        }
    }

    mapAssignmentToBeUpdated (a: any) {
        return {
            actualEndTime: a.actualEndTime,
            actualStartTime: a.actualStartTime,
            allSkills: "",                                                  
            assignId: a.assignmentId,
            assignmentDateChanged: null,                    
            assignmentDateCreated: null,                    
            associationType: a.associationType,
            cancelComment: a.comment,
            cancelReason: null,
            cancelReasonId: a.cancelReasonId,
            charge: a.charge,
            clusterId: a.clusterId,                           
            clusterName: a.clusterName,       
            competencies: [],
            countType: a.countTypeDescription,
            countTypeId: a.countTypeId,
            credentialCode: null,                   
            credentialNumber: null,                 
            credentialRenewalDate: null,
            credentialTrackingStatusId: 0,
            date: a.date,
            day: null,
            departmentCode: a.departmentCode,
            departmentId: a.departmentId,
            departmentName: a.departmentDescription,
            departmentShiftId: a.departmentShiftId,
            departmentSummaryId: 0,
            employeeType: a.employeeType,
            endTime: a.endTime, 
            fte: a.fte,
            facilityId: a.facilityId,
            facilityName: a.facilityName,
            firstName: a.staffFirstName,
            hasNotes: false,
            hireDate: null,                      
            hours: a.hours,
            hoursWorked: a.hoursWorked,
            isCancelled: a.cancel,
            isFloat: a.float,
            isOvertime: a.isOvertime,
            lastCanceledDate: a.lastCanceled, 
            lastFloatedDate: a.lastFloated,  
            lastName: a.staffLastName,
            name: a.employeeFullName,
            needId: a.needId,
            needStatusId: a.needStatusId,
            origDepartmentCode: a.origDepartmentCode,
            origDepartmentId: a.origDeptId,
            origDepartmentName: a.origDepartmentDescription,
            overTimeOccurence: 0,
            overtimeShift: a.overtimeShift,
            phoneNumber: a.phoneNumber,
            productiveDescription: a.productiveDescription,
            productiveId: a.productiveId,
            profileCharge: a.profileCharge,
            secondarySkills: a.secondarySkills,
            seniorityDate: a.seniorityDate,   
            shiftCode: a.shiftCode.trim(),
            skillLevelDescription: a.skillLevelDescription,
            skillLevelId: a.skillLevelId,
            skillSort: a.skillSortOrder,
            staffId: a.staffId,
            staffOrigDepartmentId: a.staffOrigDepartmentId,
            staffShift: null,
            staffVolume: 0,
            startTime: a.startTime, 
            unscheduledAbsence: a.unscheduledAbsence,
            unscheduledAbsenceOption: 0,
            actionableDepartment: {
                FacilityId: a.facilityId,
                FacilityName: a.facilityName,
                ClusterId: a.clusterId,                           
                ClusterName: a.clusterName,       
                DepartmentId: a.departmentId            
            },
            competencyCodes: null,
            config: [],
            dirty: false,
            dirtyFields: {},
            facilityConfig: { FacilityPriorDatesEditable: a.facilityPriorDatesEditable },      
            loadedFromDailyRoster: true
        };
    }

}
</script>
<style scoped>
.table-bordered {
    border: 1px solid #CED1D7;
}

.overtime {
    background-color: Red;
    padding: 5px;
    color: white;
}

.cancelledAssignment {
    color: #B33E3E !important;
    background-color: #F6DBDB !important;
}

.strikethrough {
    text-decoration: line-through !important;
}

.floatedAssignment {
    background-color: #D9EFFA !important;
}

.chargeAssignment {
    font-weight: bold !important;
}

.non-productive {
    color: #B33E3E !important;
    background-color: #DFE6E9 !important;
}

.other-productive {
    color: #B33E3E !important;
    background-color: #EFEFEF !important;
}

.call-assignment {
    color: #B33E3E !important;
    background-color: #D9EAD3 !important;
}

#dailyRosterSearchModal table.dailyRostersummaries-skill-grid {
    border: 1px solid #dee2e6;
    table-layout: fixed;
    display: block;
}

#dailyRosterSearchModal table.dailyRostersummaries-skill-grid td.gridUnderStaff,
#dailyRosterSearchModal table.dailyRostersummaries-skill-grid td.gridOverStaff,
#dailyRosterSearchModal table.dailyRosterExpanded-skillsummary-grid td.gridOverStaff {
        border: 1px solid #dee2e6 !important;
}

#dailyRosterSearchModal table.dailyRostersummaries-skill-grid td,
#dailyRosterSearchModal table.dailyRoster-skills-summary-grid td,
#dailyRosterSearchModal table.dailyRoster-assignments-grid td,
#dailyRosterSearchModal table.dailyRoster-assignments-grid th {
    font-size: 12px;
    word-wrap: break-word;
    padding: 0.3rem;
}

#dailyRosterSearchModal table.dailyRostersummaries-skill-grid td:nth-child(1),
#dailyRosterSearchModal table.dailyRoster-skills-summary-grid td:nth-child(1) {
    font-size: 13px;
    word-wrap: break-word;
    padding: 0.2rem;
    width: 150px;
}

#dailyRosterSearchModal table.dailyRostersummaries-skill-grid tr.dr-date-row > td {
    color: white;
    background-color: #004c8b;
    padding: 0.4rem;
}

#dailyRosterSearchModal table.dailyRostersummaries-skill-grid tr.dr-skills-heading > td,
#dailyRosterSearchModal table.dailyRosterExpanded-skillsummary-grid thead td {
    color: white;
    background-color: #004c8b;
}

#dailyRosterSearchModal table.dailyRoster-assignments-grid th, table.dailyRoster-assignments-grid td {
    font-size: 13px;
    word-wrap: break-word;
}

#dailyRosterSearchModal table.dailyRoster-assignments-grid th {
    padding: 0.4rem;
    color: white;
    background-color: #004c8b;
}

#dailyRosterSearchModal table.dailyRoster-assignments-grid td {
    padding: 0.2rem;
}

#dailyRosterSearchModal .care-assignment-sheet-grid td.overtime,
#dailyRosterSearchModal .dailyRoster-assignments-grid td.overtime {
    background-color: Red;
    display: inline-block;
    padding: 7px 10px;
}


#dailyRosterSearchModal table.dailyRostersummaries-skill-grid > :not(:first-child) {
    border-top: 0px solid currentColor;
}

.font-weight-bold{
    font-weight: 700 !important;
}

.NumColum{
    width:40px; 
}

.neutable {
  border-collapse: collapse;
  color: #1f2532;
  table-layout: fixed;
  width: 100%;
  font-size: 0.875em;
}

.neutable th {
  text-align: left;
  font-family: "HCA-Mark-Medium", "Arial", sans-serif;
  color: #58595B;
  border-bottom: 0.063rem solid #bcbcbc;
  padding: 0.5rem 0;
}
.neutable td {
  border-bottom: 0.063rem solid #bcbcbc;
  border-top: 0.063rem solid #bcbcbc;
  padding: 0.5rem 0;
}
.pointer {
    cursor: pointer;
}

.chargeIcon{
    float: right; 
    font-size: 16px;
    margin-right: 5px;
}

#btnPreviousDate {
    width: 30px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-style: solid;
    border-width: 2px;
}

#btnSelectedDate {
    border-style: solid;
    border-width: 2px 0 2px 0;
}

#btnNextDate {
    width: 30px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border-style: solid;
    border-width: 2px;
}

.k-pdf-export .buttonGroup { 
    display: none;
}

.tooltip {
  position: relative;
  display: contents !important;  
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 400px;
  background-color: #dbecf7 !important;
  color: #00435e;
  text-align: left;
  padding: 5px 10px 5px 10px;
  border-radius: 4px;
  font-size: 0.875rem !important;
  font-weight: 600;
  overflow-wrap: break-word;
  word-break: break-word;
  position: fixed;
  z-index: 1;  
}

.tooltip .tooltiptext::after{    
    rotate: 90deg;
    content: "";
    position: absolute;
    left: 396px !important;
    transform: translateX(-110%);    
    border-width: 8px !important;
    border-style: solid !important;
    border-color: transparent transparent #dbecf7 transparent !important;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

@media print {
    table.daily-roster-compressed-print td {
        font-size: xx-small !important;
        padding: 0px !important;
    }

    table.daily-roster-compressed-print th {
        font-size: xx-small !important;
        padding: 0px !important;
    }

    .chargeIcon{
        float: right; 
        font-size: 13px;
        margin-right: 5px;
    }

    .associaionIcon {
        margin-right: 5px;
        float: right;
        height: 15px;
        width: 15px;
    }
}
</style>